import { useState } from 'react';
import { Box, Drawer, Fab, Grid, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

import { getAppVersion } from '../../common/environmentHelper';
import useTranslation from '../../hooks/useTranslation';
import DarkModeSetting from './DarkModeSetting';
import LanguageSetting from './LanguageSetting';
import TestModeSetting from './TestModeSetting';

export default function SettingsPanel() {
    const t = useTranslation();
    const [isSettingsPanelOpened, setIsSettingsPanelOpened] = useState(false);
    const appVersionValue = getAppVersion();

    const handleSettingsButtonClick = () => {
        setIsSettingsPanelOpened(true);
    };

    const handleSettingsPanelClose = () => {
        setIsSettingsPanelOpened(false);
    };

    return (
        <>
            <Fab
                className='settings-button'
                size='medium'
                aria-label={t('settings')}
                onClick={handleSettingsButtonClick}
            >
                <SettingsIcon />
            </Fab>
            <Drawer
                anchor='right'
                open={isSettingsPanelOpened}
                onClose={handleSettingsPanelClose}
                PaperProps={{ sx: { p: '16px' } }}
            >
                <Grid container>
                    <Box>
                        <IconButton
                            aria-label={t('delete')}
                            sx={{ fontSize: '20px' }}
                            onClick={handleSettingsPanelClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Grid container item className='events-subtitle'>
                        {t('settings')}
                    </Grid>
                </Grid>
                <Grid container pr={1}>
                    <LanguageSetting />
                    <DarkModeSetting />
                    <TestModeSetting />
                    <Box>v. {appVersionValue}</Box>
                </Grid>
            </Drawer>
        </>
    );
}
