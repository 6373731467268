import { Badge, Box, TableCell, TableRow } from '@mui/material';

import { EventUploadStatus, IEvent } from '../../types/InterfaceTypes';
import { useGame } from '../../Providers/GameContext';
import { getEventIcon } from '../../common/eventHelper';
import { penaltyEventIds } from '../../common/testValues';
import EventActionsCell from './EventActionsCell';
import useTranslation from '../../hooks/useTranslation';
import { LangResources } from '../../types/LanguageType';

interface RecordedEventRowProps {
    event: IEvent;
}

export default function RecordedEventRow(props: RecordedEventRowProps) {
    const { event } = props;
    const { teams } = useGame();
    const t = useTranslation();
    const translatedEventName = t(`event${event.typeId}` as keyof LangResources);

    const eventIcon = getEventIcon(event.typeId);
    let eventNameElement = (
        <span>{translatedEventName}</span>
    );

    if (penaltyEventIds.includes(event.typeId)) {
        eventNameElement = (
            <Badge badgeContent='P' color='primary'>
                <Box component='span' sx={{ paddingRight: '8px' }}>{translatedEventName}</Box>
            </Badge>
        );
    }

    const eventTeam = teams.find(t => t.id === event.teamId);
    const player = eventTeam?.players.find(p => p.id === event.playerId);


    let borderColor;

    switch (event.status) {
        case EventUploadStatus.FAIL: borderColor = '#A21'; break;
        case EventUploadStatus.SUCCESS: borderColor = '#1A2'; break;
        default: borderColor = '#12A'; break;
    }

    return (
        <TableRow>
            <TableCell
                sx={{
                    paddingLeft: '7px',
                    borderLeft: '4px solid',
                    borderLeftColor: borderColor
                }}
            >
                {`${event.minute}′`}
            </TableCell>
            <TableCell>
                <Box className='flex-started-parent'>
                    <Box mr={0.5} className='flex-centered-parent'>{eventIcon}</Box>
                    {eventNameElement}
                </Box>
            </TableCell>
            <TableCell>
                <Box className='flex-started-parent'>
                    {eventTeam?.name}
                </Box>
            </TableCell>
            <TableCell>
                <Box className='flex-started-parent'>
                    {player?.number !== 0 && (
                        <Box component='span' mr={0.5} fontSize='14px'>{player?.number}</Box>
                    )}
                    <Box component='span'>{player?.nickname}</Box>
                </Box>
            </TableCell>
            <EventActionsCell event={event} />
        </TableRow>
    );
}
