import { Fab } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { useConfirmationWindowContext } from '../../Providers/ConfirmationWindowProvider';
import { useGameDispatch } from '../../Providers/GameContext';
import { GameDispatchActions } from '../../types/InterfaceTypes';
import useTranslation from '../../hooks/useTranslation';

export default function CompleteGameAction() {
    const { showModal } = useConfirmationWindowContext();
    const dispatch = useGameDispatch();
    const t = useTranslation();
    const endGameText = t('endGame');

    const handleConfirm = () => {
        dispatch({
            type: GameDispatchActions.GAME_COMPLETED
        });
    };

    const onEndGameButtonClick = () => {
        showModal(endGameText, handleConfirm, t('endGameMessage'));
    };

    return (
        <Fab
            className='end-game-button'
            size='medium'
            aria-label={endGameText}
            onClick={onEndGameButtonClick}
            color='primary'
        >
            <SendIcon />
        </Fab>
    );
}
