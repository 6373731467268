import { ThemeProvider, createTheme } from '@mui/material';

import getThemeOptions from './theme/DarkBlue';
import { GameProvider } from './Providers/GameProvider';
import { useSettingsProviderContext } from './Providers/SettingsProvider';
import ConfirmationWindowProvider from './Providers/ConfirmationWindowProvider';
import NotificationsBarProvider from './Providers/NotificationsBarProvider';
import Home from './features/Home';

export default function App() {
    const { settings } = useSettingsProviderContext();
    const themeOptionsWithPreference = getThemeOptions(settings.isDarkMode);
    const theme = createTheme(themeOptionsWithPreference);

    return (
        <ThemeProvider theme={theme}>
            <ConfirmationWindowProvider>
                <NotificationsBarProvider>
                    <GameProvider>
                        <main>
                            <Home />
                        </main>
                    </GameProvider>
                </NotificationsBarProvider>
            </ConfirmationWindowProvider>
        </ThemeProvider>
    );
}
