import { ThemeOptions } from '@mui/material/styles/createTheme';

const getThemeOptions = (isDarkMode?: boolean) => {
  const secondaryColor = isDarkMode ? '#00C1A5' : '#00716F';

  const themeOptions: ThemeOptions = {
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: isDarkMode ? '#00B1EB' : '#1D4477',
        light: '#00B1EB',
        dark: '#C7E6E6'
      },
      secondary: {
        main: secondaryColor,
        light: '#3ED8C3',
        dark: '#00514F'
      }
    },
    typography: {
      h3: {
        fontSize: '1.7em',
        fontWeight: 500
      },
      h4: {
        fontSize: '1.2em',
        fontWeight: 400,
        lineHeight: 1.1,
      },
      h6: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 1
      }
    },
    components: {
      MuiFab: {
        styleOverrides: {
          root: {
            position: 'fixed',
            bottom: '16px',
            opacity: '0.8',
            '&.fullscreen-button': {
              right: '16px'
            },
            '&.events-button': {
              left: '16px'
            },
            '&.players-button': {
              left: '80px'
            },
            '&.end-game-button': {
              right: '0',
              left: '0',
              margin: '0 auto'
            },
            '&.settings-button': {
              right: '80px'
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            border: '1px solid #ABA',
            borderRadius: '5px'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.team-name': {
              margin: '0 4px 12px',
              padding: '4px',
              borderBottom: '1px solid #ABA',
              overflow: 'hidden',
              textAlign: 'center',
              textTransform: 'uppercase',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            },
            '&.player-number': {
              marginRight: '2px',
              minWidth: '30px',
              fontWeight: 'bold'
            },
            '&.player-name': {
              position: 'relative',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              zIndex: 10
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.player-button': {
              justifyContent: 'left',
              flex: 1,
              paddingRight: '8px',
              paddingLeft: '5px',
              borderLeft: '5px solid transparent',
              textAlign: 'left',
              '&.with-yellow-card': {
                borderLeftColor: '#FCD50B'
              },
              '&.with-red-card': {
                borderLeftColor: '#E60D2E'
              }
            },
            '&.events-list-button': {
              justifyContent: 'left',
              padding: '5px 8px',
              width: '100%',
              backgroundColor: isDarkMode ? '#1A2F24' : '#F1FFF6',
              color: isDarkMode ? '#FAFAFA' : '#111',
              fontSize: '12px'
            },
            '&.small-events-list-button': {
              justifyContent: 'center',
              '.MuiButton-startIcon': {
                display: 'none'
              }
            },
            '&.shot-and-save-event-button': {
              backgroundColor: isDarkMode ? '#3A6F44' : '#C1EFB6'
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.player-edit-button': {
              padding: '6px',
              width: '42px',
              backgroundColor: '#276747',
              color: '#FFF',
              border: '1px solid #465'
            },
            '&.test-mode-collapse-button': {
              position: 'absolute',
              top: '100%',
              left: '60px',
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              color: '#FFF',
              zIndex: 2
            }
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.events-list-row': {
              justifyContent: 'space-between',
              marginBottom: '16px'
            },
            '&.events-subtitle': {
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '24px',
              paddingBottom: '8px',
              borderBottom: '1px solid #BBB'
            },
            '&.score-number': {
              fontSize: '2em',
              color: secondaryColor,
              textAlign: 'center'
            },
            '&.team-event-buttons-wrapper': {
              justifyContent: 'space-between',
              paddingRight: '8px',
              paddingLeft: '8px',
              '.events-list-button': {
                fontSize: '10px'
              }
            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '&.pre-game-table .MuiTableCell-root': {
              fontSize: '13px'
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '4px',
            fontSize: '10px',
            lineHeight: 'initial'
          }
        }
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            '&.player-fouls-badge': {
              width: '100%',
              '.MuiBadge-badge': {
                right: '5px',
                width: '20px',
                height: '20px',
                fontSize: '14px'
              }
            }
          },
          badge: {
            padding: '0 2px',
            minWidth: '16px',
            height: '16px',
            fontSize: '10px'
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            margin: '8px 16px',
            backgroundColor: isDarkMode ? '#222' : '#F3F3F3',
            '&:before': {
              display: 'none'
            },
            '&.Mui-expanded': {
              margin: '8px 16px',
              '&:first-of-type': {
                marginTop: '8px'
              }
            }
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: secondaryColor,
            color: '#FFF',
            '&.Mui-expanded': {
              minHeight: '48px'
            }
          },
          content: {
            '&.Mui-expanded': {
              margin: '12px 0'
            }
          },
          expandIconWrapper: {
            color: '#FFF'
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '8px 0'
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            '&.player-picture': {
              margin: '0 auto',
              width: '65px',
              height: '65px'
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            display: 'flex',
            alignItems: 'center',
            minWidth: '100px',
            height: '24px',
            '&.review-section-dropdown': {
              backgroundColor: isDarkMode ? '#456' : '#DAEAFF'
            }
          }
        }
      }
    }
  };

  return themeOptions;
};

export default getThemeOptions;
