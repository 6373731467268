import {
    Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ITeam } from '../../types/InterfaceTypes';
import useTranslation from '../../hooks/useTranslation';
import PreGamePlayerRow from '../Player/PreGamePlayerRow';
import TeamEditSection from './TeamEditSection';

interface PreGameTeamProps {
    team: ITeam;
}

export default function PreGameTeam(props: PreGameTeamProps) {
    const { team } = props;
    const t = useTranslation();

    return (
        <Box>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{team.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TeamEditSection team={team} />
                    <TableContainer>
                        <Table className='pre-game-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('number')}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{t('picture')}</TableCell>
                                    <TableCell>{t('name')}</TableCell>
                                    <TableCell>{t('goalkeeper')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {team.players.map((player, index) => (
                                    <PreGamePlayerRow player={player} key={index} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
