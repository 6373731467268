import { useEffect } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useSettingsProviderContext } from '../../Providers/SettingsProvider';
import { useStorage } from '../../hooks/useStorage';
import { StorageKeysEnum } from '../../common/LocalStorageManager';
import useTranslation from '../../hooks/useTranslation';

export default function LanguageSetting() {
    const { updateSettings } = useSettingsProviderContext();
    const [language, setLanguage] = useStorage<string>(StorageKeysEnum.language, () => 'bgBG');
    const t = useTranslation();

    const handleChange = (e: SelectChangeEvent) => {
        setLanguage(e.target.value);
    };

    useEffect(() => {
        updateSettings('language', language);

        // eslint-disable-next-line
    }, [language]);

    return (
        <Grid item xs={12} mb={1}>
            <FormControl fullWidth>
                <InputLabel>{t('language')}</InputLabel>
                <Select value={language} onChange={handleChange}>
                    <MenuItem value='bgBG'>Български</MenuItem>
                    <MenuItem value='enUS'>English</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
