import { createContext, useContext, useEffect, useState } from 'react';

import { updateDarkModeStyles, updateTestModeStyles } from '../features/Settings/settingsHelper';
import { ChildrenProps, SettingsFields } from '../types/InterfaceTypes';
import { LocalStorageManager, StorageKeysEnum } from '../common/LocalStorageManager';

const SettingsProviderContext = createContext({
    settings: {} as SettingsFields,
    updateSettings: (key: string, value: any) => { }
});

export const useSettingsProviderContext = () => useContext(SettingsProviderContext);

export default function SettingsProvider(props: ChildrenProps) {
    const [settings, setSettings] = useState<SettingsFields>({} as SettingsFields);

    const getSettings = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const testModeParam = queryParameters.get('test');
        const isTestMode = !!testModeParam && testModeParam === 'true';
        const storedSettings = {
            isDarkMode: LocalStorageManager.get(StorageKeysEnum.isDarkMode),
            language: LocalStorageManager.get(StorageKeysEnum.language),
            isTestMode: isTestMode
        };

        updateDarkModeStyles(!!storedSettings.isDarkMode);
        updateTestModeStyles(isTestMode);

        setSettings(storedSettings);

        return storedSettings;
    };

    const updateSettings = (key: string, value: any) => {
        setSettings((oldSettings) => {
            return {
                ...oldSettings,
                [key]: value
            }
        });
    };

    useEffect(() => {
        getSettings();

        // eslint-disable-next-line
    }, []);

    return (
        <SettingsProviderContext.Provider value={{ settings, updateSettings }}>
            {props.children}
        </SettingsProviderContext.Provider>
    );
}
