import { createContext, ReactNode, SyntheticEvent, useContext, useState } from 'react';
import { Alert, AlertColor, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ChildrenProps } from '../types/InterfaceTypes';

const NotificationsBarContext = createContext({
    showNotification: (
        message: ReactNode,
        type?: AlertColor,
        action?: ReactNode
    ) => { }
});

export const useNotificationsBarContext = () => useContext(NotificationsBarContext);

export default function NotificationsBarProvider(props: ChildrenProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState<ReactNode>(null);
    const [type, setType] = useState<AlertColor>('info');
    const [actionButton, setActionButton] = useState<ReactNode>(null);

    const showNotification = (
        message: ReactNode,
        type?: AlertColor,
        action?: ReactNode
    ) => {
        setIsOpen(false);
        setMessage(message);

        if (type) {
            setType(type);
        }

        setActionButton(action);
        setIsOpen(true);
    };

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false);
    };

    const action = (
        <>
            {actionButton}
            <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={handleClose}
            >
                <CloseIcon fontSize='small' />
            </IconButton>
        </>
    );

    const renderComponent = () => (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }} action={action}>
                {message}
            </Alert>
        </Snackbar>
    );

    return (
        <NotificationsBarContext.Provider value={{ showNotification }}>
            {props.children}
            {renderComponent()}
        </NotificationsBarContext.Provider>
    );
}
