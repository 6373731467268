import { LocalStorageManager } from './LocalStorageManager';

export const formatSeconds = (secondsParam: number) => {
    let minutes = Math.floor(secondsParam / 60);
    let seconds = secondsParam % 60;
    let minutesString = minutes > 9 ? minutes : `0${minutes}`;
    let secondsString = seconds > 9 ? seconds : `0${seconds}`;

    return `${minutesString}:${secondsString}`;
};

export const getMinuteFromSeconds = (secondsParam: number) => {
    return Math.ceil(secondsParam / 60);
};

export const getElapsedTimeFromStart = (matchId: number, matchDuration: number) => {
    const matchData = LocalStorageManager.getMatchData(matchId);

    return getElapsedTimeInner(matchDuration, matchData.startTime, matchData.secondHalfStartTime);
};

const getElapsedTimeInner = (matchDuration: number, startTime: number, secondHalfStartTime: number) => {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    if (!startTime) {
        return 0;
    }

    // The Math.min are added to ensure that the returned seconds 
    // are no bigger than the last minute of first/second half

    if (secondHalfStartTime) {
        // get seconds passed between now and start of second half
        // then add first half duration, which is:
        // converted to seconds * 60, take half of it / 2
        const matchMinute = currentTimestampInSeconds - secondHalfStartTime + (matchDuration * 30);

        return Math.min(matchMinute, (matchDuration * 60));
    }

    return Math.min(currentTimestampInSeconds - startTime, (matchDuration * 30));
};
