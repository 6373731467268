import { bgBG } from '../lang/bgBG';
import { enUS } from '../lang/enUS';
import { useSettingsProviderContext } from '../Providers/SettingsProvider';
import { LangResources } from '../types/LanguageType';

const useTranslation = () => {
    const { settings } = useSettingsProviderContext();
    let resourcesList = bgBG;

    if (settings.language === 'enUS') {
        resourcesList = enUS;
    }

    return (key: keyof LangResources) => {
        return resourcesList[key] ?? '';
    };
};

export default useTranslation;

// TODO: all translations could be moved inside the main context 
// and used from there if we don't face any performance issues