import { IEventType, INonMinuteEventIds, ITeam, ITeamAssessment } from '../types/InterfaceTypes';

export const teamsFromServer: ITeam[] = [
    {
        id: 1,
        name: 'Светът на Инструментите',
        players: [
            // {
            //     id: 27,
            //     teamId: 1,
            //     name: 'Skiril Spetrov',
            //     nickname: 'Skiril',
            //     number: 38,
            //     birthDate: '15.12.1991'
            // },
            {
                id: 18,
                teamId: 1,
                name: 'Nikola Stefanov',
                nickname: 'Nikola',
                number: 9,
                birthDate: '14.12.1991'
            },
            {
                id: 24,
                teamId: 1,
                name: 'Kalin Kalinov',
                nickname: 'Kalin',
                number: 99,
                birthDate: '14.12.1991',
                imageUrl: ''
            },
            {
                id: 8,
                teamId: 1,
                name: 'Hristo Georgiev',
                nickname: 'Hristo',
                number: 44,
                birthDate: '14.12.1991'
            },
            {
                id: 25,
                teamId: 1,
                name: 'Stanislav Georgiev',
                nickname: 'Stanislav',
                number: 46,
                birthDate: '14.12.1991'
            },
            {
                id: 4,
                teamId: 1,
                name: 'Petar Petrov',
                nickname: 'Petar',
                number: 67,
                birthDate: '14.12.1991'
            },
            {
                id: 22,
                teamId: 1,
                name: 'Petar Simeonov',
                nickname: 'Petar',
                number: 82,
                birthDate: '14.12.1991'
            },
            {
                id: 3,
                teamId: 1,
                name: 'Marin Ivanov',
                nickname: 'Marin',
                number: 11,
                birthDate: '14.12.1991'
            },
            {
                id: 2,
                teamId: 1,
                name: 'Georgi Stoyanov',
                nickname: 'Georgi',
                number: 14,
                birthDate: '14.12.1991'
            },
            {
                id: 6,
                teamId: 1,
                name: 'Atanas Stefanov',
                nickname: 'Atanas',
                number: 19,
                birthDate: '14.12.1991'
            },
            {
                id: 1,
                teamId: 1,
                name: 'Ivan Petrov',
                nickname: 'Ivan',
                number: 23,
                birthDate: '14.12.1991'
            },
            {
                id: 9,
                teamId: 1,
                name: 'Asen Stefanov',
                nickname: 'Asen',
                number: 24,
                birthDate: '14.12.1991'
            },
            {
                id: 5,
                teamId: 1,
                name: 'Stoyan Georgiev',
                nickname: 'Stoyan',
                number: 28,
                birthDate: '14.12.1991'
            },
            {
                id: 23,
                teamId: 1,
                name: 'Biser Ivanov',
                nickname: 'Biser',
                number: 32,
                birthDate: '14.12.1991'
            },
            {
                id: 7,
                teamId: 1,
                name: 'Kiril Petrov',
                nickname: 'Kiril',
                number: 37,
                birthDate: '14.12.1991'
            }
        ],
        possessionSeconds: 0
    },
    {
        id: 2,
        name: 'Продуцентите',
        players: [
            {
                id: 18,
                teamId: 2,
                name: 'Nikola Stefanov',
                nickname: 'Nikola',
                number: 9,
                birthDate: '14.12.1991'
            },
            {
                id: 10,
                teamId: 2,
                name: 'Marian Petrov',
                nickname: 'Marian',
                number: 32,
                birthDate: '14.12.1991'
            },
            {
                id: 26,
                teamId: 2,
                name: 'Filip Ivanov',
                nickname: 'Filip',
                number: 33,
                birthDate: '14.12.1991'
            },
            {
                id: 19,
                teamId: 2,
                name: 'Andrey Stefanov',
                nickname: 'Andrey',
                number: 39,
                birthDate: '14.12.1991'
            },
            {
                id: 11,
                teamId: 2,
                name: 'Oleg Stoyanov',
                nickname: 'Oleg',
                number: 41,
                birthDate: '14.12.1991'
            },
            {
                id: 17,
                teamId: 2,
                name: 'Evgeni Georgiev',
                nickname: 'Evgeni',
                number: 44,
                birthDate: '14.12.1991'
            },
            {
                id: 12,
                teamId: 2,
                name: 'Iliq Ivanov',
                nickname: 'Iliq',
                number: 11,
                birthDate: '14.12.1991'
            },
            {
                id: 21,
                teamId: 2,
                name: 'Bogdan Georgiev',
                nickname: 'Bogdan',
                number: 46,
                birthDate: '14.12.1991'
            },
            {
                id: 20,
                teamId: 2,
                name: 'Stefan Andreev',
                nickname: 'Stefan',
                number: 51,
                birthDate: '14.12.1991'
            },
            {
                id: 16,
                teamId: 2,
                name: 'Radoslav Petrov',
                nickname: 'Radoslav',
                number: 73,
                birthDate: '14.12.1991'
            },
            {
                id: 13,
                teamId: 2,
                name: 'Kristian Petrov',
                nickname: 'Kristian',
                number: 76,
                birthDate: '14.12.1991'
            },
            {
                id: 14,
                teamId: 2,
                name: 'Simon Georgiev',
                nickname: 'Simon',
                number: 82,
                birthDate: '14.12.1991'
            },
            {
                id: 15,
                teamId: 2,
                name: 'Ljubomir Stefanov',
                nickname: 'Ljubomir',
                number: 91,
                birthDate: '14.12.1991'
            }
        ],
        possessionSeconds: 0
    }
];

export const eventTypes: IEventType[] = [
    { id: 1, name: 'Shot In' },
    { id: 2, name: 'Shot Out' },
    { id: 3, name: 'Assistance' },
    { id: 4, name: 'Crossbar' },
    { id: 5, name: 'Foul' },
    { id: 6, name: 'Own Goal' },
    { id: 7, name: 'Yellow card' },
    { id: 8, name: 'Red Card' },
    { id: 9, name: 'Goal' },
    { id: 10, name: 'Save' },
    { id: 11, name: 'Goal' }, // from penalty  
    { id: 12, name: 'Save' }, // from penalty  
    { id: 13, name: 'Miss' },// from penalty
    { id: 14, name: 'Corner' },
    { id: 15, name: 'Second Yellow' },
    { id: 16, name: 'Shot In + Save' }
];

export const standardEventsListSchema: IEventType[][] = [
    [eventTypes[0], eventTypes[15]],
    [eventTypes[1], eventTypes[4]],
    [eventTypes[8], eventTypes[2]],
    [eventTypes[6], eventTypes[7]],
    [eventTypes[5], eventTypes[9]]
];

export const penaltyEventsListSchema: IEventType[] = [
    eventTypes[10],
    eventTypes[12]
];

export const teamEventsListSchema: IEventType[] = [
    eventTypes[3],
    eventTypes[13]
];

export const penaltyEventIds: IEventType['id'][] = [11, 12, 13];

export const nonMinuteEventIdsForSending: INonMinuteEventIds[] = [1, 2, 3, 5, 10, 12];

export const nonMinuteEventIds: INonMinuteEventIds[] = [...nonMinuteEventIdsForSending, 4, 14, 16];

export const minuteEventIdsForSending: number[] = [6, 7, 8, 9, 11, 13, 15];

export const numberOfFoulsThreshold = 5;

export const teamAssessmentDefaults: ITeamAssessment = {
    teamId: 0,
    score: 0,
    halfTimeScore: 0,
    posts: 0,
    corners: 0,
    kits: '',
    fairplay: '',
    numbers: '',
    photos: '',
    delay: '',
    playerOfTheMatch: '',
    cleanSheet: []
};

export const assessmentKeys: (keyof ITeamAssessment)[] = ['kits', 'fairplay', 'numbers', 'photos', 'delay'];
