import { Drawer, Grid, Typography } from '@mui/material';

import { useGame, useGameDispatch } from '../../Providers/GameContext';
import { GameDispatchActions } from '../../types/InterfaceTypes';
import { penaltyEventsListSchema, standardEventsListSchema } from '../../common/testValues';
import EventItemButton from './EventItemButton';
import useTranslation from '../../hooks/useTranslation';
import { getEventsCountPerPlayer } from '../../common/eventHelper';

export default function InsertEventMenu() {
    const { isInsertMenuOpened, selectedPlayer, events } = useGame();
    const dispatch = useGameDispatch();
    const t = useTranslation();

    const handleInsertEventMenuClose = () => {
        dispatch({ type: GameDispatchActions.CLOSE_INSERT_MENU });
    };

    const eventCountsMap = getEventsCountPerPlayer(selectedPlayer, events);

    return (
        <Drawer
            anchor='bottom'
            open={isInsertMenuOpened}
            onClose={handleInsertEventMenuClose}
            PaperProps={{ sx: { px: '8px' } }}
        >
            {selectedPlayer && (
                <Grid container p={1} justifyContent='center'>
                    <Grid container item className='events-subtitle'>
                        <Typography variant='h3' component='span' className='player-number'>{selectedPlayer.number}</Typography>
                        <Typography variant='h6' component='span' className='player-name'>{selectedPlayer.name}</Typography>
                    </Grid>
                </Grid>
            )}

            {standardEventsListSchema.map((eventsRow, index) => (
                <Grid key={index} container className='events-list-row'>
                    {eventsRow.map((eventItem) => {
                        let eventCount = eventCountsMap.get(eventItem.id) ?? 0;

                        if (eventItem.id === 7) {
                            eventCount += eventCountsMap.get(15) ?? 0;
                        }
                        else if (eventItem.id === 15) {
                            eventCount += eventCountsMap.get(7) ?? 0;
                        }

                        return (
                            <EventItemButton key={eventItem.id} eventItem={eventItem} cellSize={5.75} eventCount={eventCount} />
                        );
                    })}
                </Grid>
            ))}

            <Grid container p={1} justifyContent='center'>
                <Grid container item className='events-subtitle'>
                    {t('penaltyRelated')}
                </Grid>
            </Grid>
            <Grid container className='events-list-row'>
                {penaltyEventsListSchema.map((eventItem) => {
                    let eventCount = eventCountsMap.get(eventItem.id) ?? 0;

                    return (
                        <EventItemButton key={eventItem.id} eventItem={eventItem} cellSize={5.75} eventCount={eventCount} />
                    );
                })}
            </Grid>
        </Drawer>
    );
}
