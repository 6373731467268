import { useState } from 'react';
import { IconButton, TableCell } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { EventUploadStatus, IEvent } from '../../types/InterfaceTypes';
import useTranslation from '../../hooks/useTranslation';
import { nonMinuteEventIds } from '../../common/testValues';
import EventEditPopup from './EventEditPopup';

interface EventActionsCellProps {
    event: IEvent;
}

export default function EventActionsCell(props: EventActionsCellProps) {
    const { event } = props;
    const t = useTranslation();
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const isNonMinuteEvent = nonMinuteEventIds.indexOf(event.typeId as any) !== -1;

    const handleEditButtonClick = () => {
        setIsDialogOpened(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpened(false);
    };

    return (
        <TableCell align='right'>
            <IconButton
                aria-label={t('edit')}
                sx={{ padding: '6px', fontSize: '20px' }}
                onClick={handleEditButtonClick}
                disabled={!isNonMinuteEvent && event.status !== EventUploadStatus.SUCCESS}
            >
                <EditIcon fontSize='inherit' />
            </IconButton>

            {isDialogOpened && (
                <EventEditPopup event={event} handleDialogClose={handleDialogClose} />
            )}
        </TableCell>
    );
}
