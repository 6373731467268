import { getAppVersion } from './environmentHelper';

export const matchKeyPrefix = 'match';

export const StorageKeysEnum = {
    isDarkMode: 'isDarkMode',
    language: 'language',
    appVersion: 'appVersion',
    appState: 'appState',
    startTime: 'startTime',
    secondHalfStartTime: 'secondHalfStartTime',
    lastBackupTimestamp: 'lastBackupTimestamp'
    // when you add item here check IIFE method below
} as const;

type EventsQueueType = {
    -readonly [key in keyof typeof StorageKeysEnum]?: VoidFunction[];
};

export const LocalStorageManager = (function (localStorage) {
    const eventsQueue: EventsQueueType = {};

    const get = (key: keyof typeof StorageKeysEnum) => {
        let storedValue = localStorage.getItem(key);

        if (storedValue !== null && storedValue !== undefined && storedValue !== '[]' && storedValue !== '{}') {
            return JSON.parse(storedValue);
        }

        return undefined;
    };

    const getMatchData = (matchId: number) => {
        const key = `${matchKeyPrefix}${matchId}` as any;

        return get(key) ?? {};
    };

    const set = (key: keyof typeof StorageKeysEnum, value: any) => {
        localStorage.setItem(key, JSON.stringify(value));

        if (!eventsQueue[key]) {
            return;
        }

        (eventsQueue[key] as any[]).forEach(callback => callback());
    };

    const setMatchData = (matchId: number, value: Object) => {
        const key = `${matchKeyPrefix}${matchId}` as any;
        const matchData = get(key) ?? {};

        set(key, {
            ...matchData,
            ...value
        });
    };

    const deleteKey = (key: keyof typeof StorageKeysEnum) => {
        localStorage.removeItem(key);

        if (!eventsQueue[key]) {
            return;
        }

        (eventsQueue[key as keyof typeof StorageKeysEnum] as any[]).forEach(callback => callback());
    };

    const deleteMatchData = (matchId: number) => {
        const key = `${matchKeyPrefix}${matchId}` as any;

        deleteKey(key);
    };

    const clear = () => {
        localStorage.clear();

        Object.entries(eventsQueue).forEach(([key, value]) => {
            (value as any[]).forEach(callback => callback());
        });
    };

    const initialize = () => {
        // logic for storing only the last 10 matches

        const queryParameters = new URLSearchParams(window.location.search);
        const matchIdQueryParam = queryParameters.get('matchId');
        const matchIdFromServer = parseInt(matchIdQueryParam ?? '0');

        if (!matchIdFromServer) {
            return;
        }

        const matchKeyFromServer = `${matchKeyPrefix}${matchIdFromServer}`;
        let matchKeys = [];
        let tempOldestStartTime = Number.MAX_SAFE_INTEGER;
        let matchKeyWithOldestStartTime = 'empty';

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            if (key !== null && key.startsWith(matchKeyPrefix)) {
                const tempMatch = get(key as any);

                matchKeys.push(key);

                if (!tempMatch.startTime || tempMatch.startTime < tempOldestStartTime) {
                    tempOldestStartTime = tempMatch.startTime;
                    matchKeyWithOldestStartTime = key;
                }
            }
        }

        if (matchKeys.length < 10 || matchKeys.includes(matchKeyFromServer)) {
            return;
        }

        deleteKey(matchKeyWithOldestStartTime as any);
    };

    (() => {
        const appVersionKey = 'appVersion';
        const appVersionValue = getAppVersion();
        const appVersion = get(appVersionKey);

        if (appVersion !== appVersionValue) {
            deleteKey(StorageKeysEnum.appVersion);
            deleteKey(StorageKeysEnum.appState);
            deleteKey(StorageKeysEnum.startTime);
            deleteKey(StorageKeysEnum.secondHalfStartTime);

            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);

                if (key !== null && key.startsWith(matchKeyPrefix)) {
                    deleteKey(key as any);
                }
            }

            set(appVersionKey, appVersionValue);
        }
    })();

    initialize();

    return {
        set: set,
        setMatchData: setMatchData,
        get: get,
        getMatchData: getMatchData,
        delete: deleteKey,
        deleteMatchData: deleteMatchData,
        clear: clear,
        onChange: (key: keyof typeof StorageKeysEnum, callback: VoidFunction) => {
            if (!eventsQueue[key]) {
                eventsQueue[key] = [];
            }

            if (!callback || typeof callback !== 'function') {
                return;
            }

            (eventsQueue[key] as any).push(callback);
        },
        offChange: (key: keyof typeof StorageKeysEnum) => {
            eventsQueue[key] = [];
        }
    };
})(window.localStorage);
