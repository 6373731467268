import { Grid } from '@mui/material';

import { useGame } from '../../Providers/GameContext';
import Team from '../Team/Team';

export default function InGameScreen() {
    const { teams } = useGame();

    return (
        <Grid container>
            {teams.map((team, index) => (
                <Grid item key={team.id} xs={6}>
                    <Team team={team} index={index} />
                </Grid>
            ))}
        </Grid>
    );
}
