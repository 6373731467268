import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ITeam, ITeamAssessment } from '../../types/InterfaceTypes';
import TeamReviewSection from './TeamReviewSection';

interface PostGameTeamProps {
    team: ITeam;
    teamAssessment: ITeamAssessment;
    handleChange: (id: any, value: any) => void;
}

export default function PostGameTeam(props: PostGameTeamProps) {
    const { team } = props;

    return (
        <Box mb={2}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{team.name}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 1 }}>
                    <TeamReviewSection team={team} teamAssessment={props.teamAssessment} handleChange={props.handleChange} />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
