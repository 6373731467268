import { Badge, Box, Button, Typography } from '@mui/material';
import BackHandIcon from '@mui/icons-material/BackHand';

import { GameDispatchActions, IPlayer } from '../../types/InterfaceTypes';
import { useGame, useGameDispatch } from '../../Providers/GameContext';
import { numberOfFoulsThreshold } from '../../common/testValues';
import PlayerNumberModifiedIndicator from './PlayerNumberModifiedIndicator';

interface PlayerProps {
    player: IPlayer;
}

export default function Player(props: PlayerProps) {
    const { events } = useGame();
    const dispatch = useGameDispatch();
    const playerEvents = events.filter((e) =>
        e.teamId === props.player.teamId && e.playerId === props.player.id
    );
    const playerFoulsCount = playerEvents.filter((e) => e.typeId === 5).length;
    let playerClassName = 'player-button';
    const playerHasRedCard = !!playerEvents.find((e) => e.typeId === 8 || e.typeId === 15);
    const playerHasYellowCard = !!playerEvents.find((e) => e.typeId === 7);

    if (playerHasRedCard) {
        playerClassName = 'player-button with-red-card';
    }
    else if (playerHasYellowCard) {
        playerClassName = 'player-button with-yellow-card';
    }

    const onPlayerClick = () => {
        dispatch({
            type: GameDispatchActions.OPEN_INSERT_MENU,
            value: props.player
        });
    };

    const playerFoulsDisplayCount = playerFoulsCount >= numberOfFoulsThreshold - 1 ? playerFoulsCount : 0;
    const playerFoulsBadgeColor = playerFoulsDisplayCount === numberOfFoulsThreshold - 1 ? 'warning' : 'error';

    return (
        <Box mb={1.5} px={1} display='flex' position='relative'>
            <Badge
                badgeContent={playerFoulsDisplayCount}
                color={playerFoulsBadgeColor}
                component='div'
                className='player-fouls-badge'
            >
                <Button
                    variant={props.player.selected ? 'contained' : 'outlined'}
                    onClick={onPlayerClick}
                    className={playerClassName}
                    disabled={playerHasRedCard}
                >
                    <Typography variant='h3' component='span' className='player-number'>
                        {props.player.number}
                        <PlayerNumberModifiedIndicator player={props.player} />
                    </Typography>
                    <Typography variant='h6' component='span' className='player-name'>{props.player.nickname}</Typography>

                    {props.player.goalkeeper && (
                        <Box position='absolute' top='8px' right='15px' zIndex='2' color='#FAFAFA'>
                            <BackHandIcon />
                        </Box>
                    )}
                </Button>
            </Badge>
        </Box >
    );
}
