import { Box, Button, Grid } from '@mui/material';

import useTranslation from '../../hooks/useTranslation';
import { useConfirmationWindowContext } from '../../Providers/ConfirmationWindowProvider';
import { useGame } from '../../Providers/GameContext';
import PostGameTeam from '../Team/PostGameTeam';
import { useState } from 'react';
import { ICompleteMatchData, INonMinuteEvent, IParticipatingPlayer, IPlayer, ITeam, ITeamAssessment } from '../../types/InterfaceTypes';
import { assessmentKeys, minuteEventIdsForSending, nonMinuteEventIdsForSending, teamAssessmentDefaults } from '../../common/testValues';
import GameApi from '../../api/GameApi';
import { useNotificationsBarContext } from '../../Providers/NotificationsBarProvider';
import { LocalStorageManager } from '../../common/LocalStorageManager';
import { useSettingsProviderContext } from '../../Providers/SettingsProvider';

export default function PostGameScreen() {
    const { showModal } = useConfirmationWindowContext();
    const notify = useNotificationsBarContext();
    const { teams, matchId, leagueId, events, duration } = useGame();
    const t = useTranslation();
    const { settings } = useSettingsProviderContext();
    const allTeamAssessmentDefaults: ITeamAssessment[] = teams.map(team => {
        return {
            ...teamAssessmentDefaults,
            teamId: team.id
        };
    });
    const [teamAssessments, setTeamAssessments] = useState<ITeamAssessment[]>(allTeamAssessmentDefaults);
    const [isMatchSaved, setIsMatchSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fieldsToValidate: (keyof ITeamAssessment)[] = [...assessmentKeys, 'playerOfTheMatch'];
    const isFormInvalid = teamAssessments.some((ta) => {
        return fieldsToValidate.some((fieldKey) => ta[fieldKey] === '');
    });

    const handleConfirm = async () => {
        setIsLoading(true);

        const allNonMinuteEvents: INonMinuteEvent[] = [];

        teams.forEach((team) => {
            nonMinuteEventIdsForSending.forEach((eventTypeId) => {
                const matchingEvents = events.filter((e) => e.typeId === eventTypeId && e.teamId === team.id);

                if (matchingEvents.length === 0) {
                    return;
                }

                const eventsPerPlayerMap = new Map();

                matchingEvents.forEach((e) => {
                    const existingPlayerEvents = eventsPerPlayerMap.get(e.playerId) ?? [];

                    existingPlayerEvents.push(e);

                    eventsPerPlayerMap.set(e.playerId, existingPlayerEvents);
                });

                eventsPerPlayerMap.forEach((epp, key) => {
                    allNonMinuteEvents.push({
                        typeId: eventTypeId,
                        sum: epp.length,
                        matchId: matchId,
                        leagueId: leagueId,
                        teamId: team.id,
                        playerId: key
                    });
                });
            })
        });

        const playerToParticipantDto = (p: IPlayer): IParticipatingPlayer => {
            return {
                id: p.id,
                teamId: p.teamId,
                position: p.position
            };
        };

        const allMinuteEvents = events.filter((e) => minuteEventIdsForSending.includes(e.typeId)).map((e) => {
            return {
                leagueId: leagueId,
                matchId: matchId,
                teamId: e.teamId,
                playerId: e.playerId,
                typeId: e.typeId,
                minute: e.minute
            };
        }) as any[];

        const completeMatchData: ICompleteMatchData = {
            matchId: matchId,
            leagueId: leagueId,
            teams: teamAssessments.map((ta) => {
                const scoreEvents = events.filter((e) =>
                    ((e.typeId === 9 || e.typeId === 11) && e.teamId === ta.teamId)
                    || (e.typeId === 6 && e.teamId !== ta.teamId)
                );

                return {
                    ...ta,
                    score: scoreEvents.length,
                    halfTimeScore: scoreEvents.filter((e) => e.minute <= duration / 2).length,
                    posts: events.filter((e) => e.typeId === 4 && e.teamId === ta.teamId).length,
                    corners: events.filter((e) => e.typeId === 14 && e.teamId === ta.teamId).length
                };
            }),
            nonMinuteEvents: allNonMinuteEvents,
            minuteEvents: allMinuteEvents,
            participatingPlayers: [
                ...teams[0].players.filter(p => p.selected).map(playerToParticipantDto),
                ...teams[1].players.filter(p => p.selected).map(playerToParticipantDto)
            ],
            testMatchData: JSON.stringify(LocalStorageManager.getMatchData(matchId))
        };
        const requestMethod = settings.isTestMode ? GameApi.mockSuccessRequest : GameApi.completeMatch;
        const completeMatchResponse = await requestMethod(completeMatchData);

        setIsLoading(false);

        if (!completeMatchResponse.isSuccess) {
            notify.showNotification(t('somethingWentWrong'), 'error');

            return;
        }

        notify.showNotification(t('matchSavedSuccessfully'), 'success');

        setIsMatchSaved(true);
        LocalStorageManager.deleteMatchData(matchId);

        // TODO: redirect to matches list
    };

    const onCompleteGameButtonClick = () => {
        showModal(t('submitGame'), handleConfirm, t('submitGameMessage'));
    };

    const handleChange = (teamId: ITeam['id'], id: any, value: any) => {
        setTeamAssessments((oldTeams) => {
            return [...oldTeams].map((oldTeam) => {
                if (oldTeam.teamId === teamId) {
                    return {
                        ...oldTeam,
                        [id]: value
                    };
                }

                return oldTeam;
            });
        });
    };

    return (
        <Grid container justifyContent='center'>
            <Grid container item direction='column' mb={2}>
                {teams.map((team, index) => (
                    <PostGameTeam
                        key={team.id}
                        team={team}
                        teamAssessment={teamAssessments[index]}
                        handleChange={(id: any, value: any) => { handleChange(team.id, id, value); }}
                    />
                ))}
            </Grid>
            <Grid item mb={2}>
                <Box>
                    <Button
                        variant='contained'
                        className='flex-centered-parent'
                        onClick={onCompleteGameButtonClick}
                        disabled={isMatchSaved || isFormInvalid || isLoading}
                    >
                        {t('submitGame')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}
