import { Box } from '@mui/material';
import { IPlayer } from '../../types/InterfaceTypes';

interface PlayerEditPopupProps {
    player: IPlayer;
}

export default function PlayerNumberModifiedIndicator(props: PlayerEditPopupProps) {
    if (props.player.number === props.player.originalNumber) {
        return null;
    }

    return (
        <Box component='span' className='modified-number-indicator'>*</Box>
    );
}
