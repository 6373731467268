import { useGame } from '../../Providers/GameContext';
import SettingsPanel from '../Settings/SettingsPanel';
import RecordedEventsList from '../Events/RecordedEventsList';
import FullscreenAction from './FullscreenAction';
import CompleteGameAction from './CompleteGameAction';
import TogglePlayersAction from './TogglePlayersAction';

export default function ActionButtons() {
    const { isGameStarted, isGameCompleted } = useGame();

    return (
        <>
            {isGameStarted && (
                <RecordedEventsList />
            )}

            {isGameStarted && !isGameCompleted && (
                <>
                    <TogglePlayersAction />
                    <CompleteGameAction />
                </>
            )}

            <SettingsPanel />
            <FullscreenAction />
        </>
    );
}
