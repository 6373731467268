import EventIcon from '@mui/icons-material/Event';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

import { IEvent, IEventType, IPlayer, ITeam } from '../types/InterfaceTypes';
import { LocalStorageManager, StorageKeysEnum } from './LocalStorageManager';
import GameApi from '../api/GameApi';

export const getEventIcon = (eventTypeId: IEventType['id']) => {
    let buttonIcon;

    switch (eventTypeId) {
        case 1:
            buttonIcon = (<ExitToAppIcon fontSize='small' />);
            break;
        case 2:
            buttonIcon = (<OpenInNewIcon fontSize='small' />);
            break;
        case 3:
            buttonIcon = (<SwipeUpAltIcon fontSize='small' />);
            break;
        case 4:
            buttonIcon = (<CallMissedOutgoingIcon fontSize='small' />);
            break;
        case 5:
            buttonIcon = (<SportsKabaddiIcon fontSize='small' />);
            break;
        case 6:
            buttonIcon = (<MoodBadIcon fontSize='small' />);
            break;
        case 7:
            buttonIcon = (<ContactPageIcon fontSize='small' sx={{ color: '#FCD50B' }} />);
            break;
        case 8:
        case 15:
            buttonIcon = (<ContactPageIcon fontSize='small' sx={{ color: '#E60D2E' }} />);
            break;
        case 9:
            buttonIcon = (<SportsSoccerIcon fontSize='small' />);
            break;
        case 10:
            buttonIcon = (<WavingHandIcon fontSize='small' />);
            break;
        case 11:
            buttonIcon = (<SportsSoccerIcon fontSize='small' />);
            break;
        case 12:
            buttonIcon = (<WavingHandIcon fontSize='small' />);
            break;
        case 13:
            buttonIcon = (<CallMissedOutgoingIcon fontSize='small' />);
            break;
        case 14:
            buttonIcon = (<GolfCourseIcon fontSize='small' />);
            break;
        default:
            buttonIcon = (<EventIcon fontSize='small' />);
    }

    return buttonIcon;
}

export const getEventsCountPerPlayer = (player: IPlayer | null, events: IEvent[]) => {
    const eventCountsMap = new Map<IEventType['id'], number>();
    let playerId = player ? player.id : 0;
    let teamId = player ? player.teamId : 0;

    events.forEach((e) => {
        if (teamId && e.teamId !== teamId) {
            return;
        }

        if (e.playerId !== playerId) {
            return;
        }

        const lastCount = eventCountsMap.get(e.typeId) ?? 0;

        eventCountsMap.set(e.typeId, lastCount + 1);
    });

    return eventCountsMap;
};

export const getEventsCountPerTeam = (teamId: ITeam['id'], events: IEvent[]) => {
    const eventCountsMap = new Map<IEventType['id'], number | undefined>();

    events.forEach((e) => {
        if (e.teamId !== teamId) {
            return;
        }

        const lastCount = eventCountsMap.get(e.typeId) ?? 0;

        eventCountsMap.set(e.typeId, lastCount + 1);
    });

    return eventCountsMap;
};

export const saveBackup = async (matchId: number) => {
    const lastBackupTimestamp = parseInt(LocalStorageManager.get(StorageKeysEnum.lastBackupTimestamp) ?? '0');

    if (Date.now() - lastBackupTimestamp < 60000) { // hard-coded one minute
        return;
    }

    const testMatchData = LocalStorageManager.getMatchData(matchId);

    // delete testMatchData.ballPossessionIndex;
    // delete testMatchData.selectedPlayer;
    // delete testMatchData.isInsertMenuOpened;
    // delete testMatchData.isShowingAllPlayers;

    testMatchData.teams = testMatchData.teams.map((t: ITeam) => {
        return {
            ...t,
            players: t.players.filter((t: any) => t.selected)
        };
    });

    const backupResponse = await GameApi.sendMatchData(matchId, { data: JSON.stringify(testMatchData) });

    if (backupResponse) {
        LocalStorageManager.set(StorageKeysEnum.lastBackupTimestamp, Date.now());
    }
};
