import { LangResources } from '../types/LanguageType';

export const enUS: LangResources = {
    language: 'Language',
    settings: 'Settings',
    darkMode: 'Dark Mode',
    testMode: 'Test Mode',
    confirm: 'Confirm',
    cancel: 'Cancel',
    ok: 'Ok',
    penaltyRelated: 'PENALTY-related',
    failedTryAgain: 'Failed, click to try again',
    deleteEvent: 'Delete event?',
    deleteEventMessage: 'Are you sure you want to delete this event?',
    events: 'Events',
    noEvents: 'No recorded events yet',
    event: 'Event',
    player: 'Player',
    status: 'Status',
    actions: 'Actions',
    startGame: 'Start game',
    continueGame: 'Continue game',
    stopGame: 'Stop game',
    startTimer: 'Start the game timer?',
    startTimerMessage: 'Are you sure you want to start the game timer?',
    stopTimer: 'Stop the game timer?',
    stopTimerMessage: 'Are you sure you want to stop the game timer?',
    editMinute: 'Edit match minute',
    changePlayerNumber: 'Change player number',
    event1: 'Shot In',
    event2: 'Shot Out',
    event3: 'Assistance',
    event4: 'Crossbar',
    event5: 'Foul',
    event6: 'Own Goal',
    event7: 'Yellow card',
    event8: 'Red Card',
    event9: 'Goal',
    event10: 'Save',
    event11: 'Goal',
    event12: 'Save',
    event13: 'Miss',
    event14: 'Corner',
    event15: '2nd yellow',
    event16: 'Shot In + Save',
    edit: 'Edit',
    delete: 'Delete',
    enterFullscreen: 'Enter fullscreen',
    exitFullscreen: 'Exit fullscreen',
    showPlayers: 'Show all players',
    hidePlayers: 'Hide unrelated players',
    editPlayer: 'Edit Player',
    goalkeeper: 'Goalkeeper',
    changePlayerNickname: 'Change player nickname',
    noMatchId: 'No match ID provided.',
    did: 'did',
    playerNumber: 'Player Number',
    playerNickname: 'Player Nickname',
    teamColor: 'Team Color',
    teamColorMessage: 'Choose team color',
    number: 'Number',
    picture: 'Picture',
    name: 'Name',
    noColor: 'No color',
    color1: 'Red',
    color2: 'Green',
    color3: 'Blue',
    color4: 'Yellow',
    color5: 'Purple',
    color6: 'Orange',
    color7: 'White',
    color8: 'Black',
    undo: 'Undo',
    ballPossession: 'Ball possession',
    ballNeutral: 'Neutral',
    has: 'has',
    fouls: 'fouls',
    team: 'Team',
    editEvent: 'Edit event',
    minute: 'Minute',
    endGame: 'End game',
    endGameMessage: 'Are you sure you want to end the game?',
    kitsTitle: 'Kits',
    fairplayTitle: 'Fairplay',
    numbersTitle: 'Numbers',
    photosTitle: 'Photos',
    delayTitle: 'Delay',
    playerOfTheMatch: 'Player of the match',
    cleanSheet: 'Clean Sheet',
    submitGame: 'Submit game stats',
    submitGameMessage: 'Are you sure you want to submit game stats?',
    halfTime: 'Half time',
    fullTime: 'Full time',
    somethingWentWrong: 'Something went wrong...',
    matchSavedSuccessfully: 'Match is successfully saved',
    eventUndone: 'Event undone successfully',
    eventUpdatedSuccessfully: 'Event updated successfully',
    eventDeletedSuccessfully: 'Event deleted successfully',
    selectPlaceholder: 'Select...',
    originalNumber: 'Original number',
    resetMatchProgress: 'Reset match progress',
    resetMatchProgressMessage: 'Are you sure you want to reset match progress? This will delete all local changes.',
    exit: 'Exit',
};
