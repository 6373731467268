import { isDevEnv } from '../common/environmentHelper';
import { ICompleteMatchData, IEvent, IMatch } from '../types/InterfaceTypes';
import { endpoints } from './Endpoints';
import httpClient, { ApiResponse } from './HttpClient';
import { teamsFromServer } from '../common/testValues';

const GameApi = {
    getMatchData: async (matchId: IMatch['id']): Promise<ApiResponse<IMatch>> => {
        if (isDevEnv()) {
            // TODO: for DEV purposes only
            const initialAppState: IMatch = {
                id: matchId,
                leagueId: 1,
                teams: teamsFromServer,
                duration: 50
            };

            return {
                isSuccess: true,
                data: initialAppState,
                errorMessage: ''
            };
            // for DEV purposes only
        }

        const url = endpoints.matchData.replace('{id}', `${matchId}`);
        const result = await httpClient.get<IMatch>(url);

        return result;
    },
    sendMatchData: async (matchId: IMatch['id'], matchData: any): Promise<ApiResponse<null>> => {
        const url = endpoints.matchData.replace('{id}', `${matchId}`);
        const result = await httpClient.post<null>(url, matchData);

        return result;
    },
    startMatch: async (matchId: IMatch['id']): Promise<ApiResponse<null>> => {
        const url = endpoints.startMatch.replace('{id}', `${matchId}`);
        const result = await httpClient.get<null>(url);

        return result;
    },
    startSecondHalf: async (matchId: IMatch['id']): Promise<ApiResponse<null>> => {
        const url = endpoints.startSecondHalf.replace('{id}', `${matchId}`);
        const result = await httpClient.get<null>(url);

        return result;
    },
    completeMatch: async (matchData: ICompleteMatchData): Promise<ApiResponse<null>> => {
        const url = endpoints.completeMatch.replace('{id}', `${matchData.matchId}`);
        const result = await httpClient.post<null>(url, matchData);

        return result;
    },
    getMatchEvents: async (matchId: number): Promise<ApiResponse<IEvent[]>> => {
        const url = endpoints.matchEvents.replace('{id}', `${matchId}`);
        const result = await httpClient.get<IEvent[]>(url);

        return result;
    },
    saveEvent: async (eventData: IEvent): Promise<ApiResponse<IEvent>> => {
        const url = endpoints.matchEvents.replace('{id}', `${eventData.matchId}`);
        const result = await httpClient.post<IEvent>(url, eventData);

        return result;
    },
    editEvent: async (eventData: IEvent): Promise<ApiResponse<IEvent>> => {
        const url = endpoints.matchEvent
            .replace('{id}', `${eventData.matchId}`)
            .replace('{eventId}', `${eventData.id}`);
        const result = await httpClient.patch<IEvent>(url, eventData);

        return result;
    },
    deleteEvent: async (eventData: IEvent): Promise<ApiResponse<IEvent>> => {
        const url = endpoints.matchEvent
            .replace('{id}', `${eventData.matchId}`)
            .replace('{eventId}', `${eventData.id}`);
        const result = await httpClient.delete<IEvent>(url, {});

        return result;
    },
    mockSuccessRequest: async (data: any): Promise<ApiResponse<any>> => {
        return {
            isSuccess: true,
            data: data,
            errorMessage: ''
        };
    }
};

export default GameApi;
