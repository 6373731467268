import { Grid } from '@mui/material';

import { useGame } from '../../Providers/GameContext';
import PreGameTeam from '../Team/PreGameTeam';

export default function PreGameScreen() {
    const { teams } = useGame();

    return (
        <Grid container direction='column'>
            {teams.map((team) => (
                <PreGameTeam key={team.id} team={team} />
            ))}
        </Grid>
    );
}
