import { LocalStorageManager } from '../common/LocalStorageManager';
import {
    EventUploadStatus, GameDispatchActions, IEvent,
    IGameContext, IGameDispatchContext, IPlayer
} from '../types/InterfaceTypes';

export const gameReducer = (game: IGameContext, action: IGameDispatchContext): IGameContext => {
    let newState = {} as IGameContext;

    switch (action.type) {
        case GameDispatchActions.OPEN_INSERT_MENU:
            newState = {
                ...game,
                isInsertMenuOpened: true,
                selectedPlayer: action.value
            };
            break;
        case GameDispatchActions.CLOSE_INSERT_MENU:
            newState = {
                ...game,
                isInsertMenuOpened: false,
                selectedPlayer: null
            };
            break;
        case GameDispatchActions.ADD_EVENT:
            const additionalEvents: IEvent[] = [];

            if (action.value.typeId !== 16) {
                additionalEvents.push(action.value);
            }

            // on 'Goal' add 'Shot In'
            if (action.value.typeId === 9 || action.value.typeId === 11) {
                additionalEvents.push({
                    ...action.value,
                    status: EventUploadStatus.SUCCESS,
                    typeId: 1,
                    id: Date.now() + 1
                });
            }

            // Shot In + Save
            if (action.value.typeId === 16) {
                additionalEvents.push({
                    ...action.value,
                    status: EventUploadStatus.SUCCESS,
                    typeId: 1
                });

                const otherTeamGoalkeeper = game.teams.find(t => t.id !== action.value.teamId)?.players?.find(p => p.goalkeeper);

                if (otherTeamGoalkeeper) {
                    additionalEvents.push({
                        ...action.value,
                        status: EventUploadStatus.SUCCESS,
                        typeId: 10,
                        id: Date.now() + 1,
                        teamId: otherTeamGoalkeeper.teamId,
                        playerId: otherTeamGoalkeeper.id
                    });
                }
            }

            newState = {
                ...game,
                events: [
                    ...game.events,
                    ...additionalEvents
                ]
            };
            break;
        case GameDispatchActions.EVENT_UPLOAD_IN_PROGRESS:
            newState = {
                ...game,
                events: game.events.map((e) => {
                    if (e.id === action.value) {
                        return {
                            ...e,
                            status: EventUploadStatus.PENDING
                        };
                    }

                    return e;
                })
            };
            break;
        case GameDispatchActions.EVENT_UPLOAD_SUCCEEDED:
            newState = {
                ...game,
                events: game.events.map((e) => {
                    if (e.id === action.value.oldInternalId) {
                        return {
                            ...e,
                            id: action.value.id,
                            status: EventUploadStatus.SUCCESS
                        };
                    }

                    return e;
                })
            };
            break;
        case GameDispatchActions.EVENT_UPLOAD_FAILED:
            newState = {
                ...game,
                events: game.events.map((e) => {
                    if (e.id === action.value) {
                        return {
                            ...e,
                            status: EventUploadStatus.FAIL
                        };
                    }

                    return e;
                })
            };
            break;
        case GameDispatchActions.PLAYER_CLICK:
            newState = {
                ...game,
                teams: game.teams.map((oldTeam) => {
                    if (oldTeam.id !== action.value.teamId) {
                        return oldTeam;
                    }

                    return {
                        ...oldTeam,
                        players: oldTeam.players.map((player: IPlayer) => {
                            if (player.id === action.value.playerId) {
                                return {
                                    ...player,
                                    goalkeeper: player.selected ? false : player.goalkeeper,
                                    selected: !player.selected
                                };
                            }

                            return player;
                        }) ?? []
                    };
                })
            };
            break;
        case GameDispatchActions.BALL_POSSESSION_CHANGE:
            newState = {
                ...game,
                ballPossessionIndex: action.value
            };
            break;
        case GameDispatchActions.START_TIMER:
            const startTimeKey = game.isGameStarted ? 'secondHalfStartTime' : 'startTime';

            newState = {
                ...game,
                isGameStarted: true,
                isGameHalfTime: false,
                isGameSecondHalf: game.isGameStarted,
                [startTimeKey]: Math.floor(Date.now() / 1000)
            };

            break;
        case GameDispatchActions.TOGGLEDISPLAYALLPLAYERS:
            newState = {
                ...game,
                isShowingAllPlayers: action.value === undefined ? !game.isShowingAllPlayers : Boolean(action.value)
            };
            break;
        case GameDispatchActions.GAME_COMPLETED:
            newState = {
                ...game,
                isGameCompleted: true
            };
            break;
        case GameDispatchActions.PLAYER_NUMBER_EDIT:
            newState = {
                ...game,
                teams: game.teams.map((oldTeam) => {
                    if (oldTeam.id !== action.value.teamId) {
                        return oldTeam;
                    }

                    return {
                        ...oldTeam,
                        players: oldTeam.players.map((player: IPlayer) => {
                            if (player.id === action.value.playerId) {
                                return {
                                    ...player,
                                    number: action.value.customNumber ?? player.number
                                };
                            }

                            return player;
                        }) ?? []
                    };
                })
            };
            break;
        case GameDispatchActions.PLAYER_GOALKEEPER_EDIT:
            newState = {
                ...game,
                teams: game.teams.map((oldTeam) => {
                    if (oldTeam.id !== action.value.teamId) {
                        return oldTeam;
                    }

                    return {
                        ...oldTeam,
                        players: oldTeam.players.map((player: IPlayer) => {
                            return {
                                ...player,
                                goalkeeper: player.id === action.value.playerId && !!action.value.isGoalkeeper
                            };
                        }) ?? []
                    };
                })
            };
            break;
        case GameDispatchActions.EDIT_EVENT:
            newState = {
                ...game,
                events: game.events.map((e) => {
                    if (e.id === action.value.id) {
                        return {
                            ...e,
                            ...action.value
                        };
                    }

                    return e;
                })
            };
            break;
        case GameDispatchActions.DELETE_EVENT:
            const newEventsListAfterDeletion: IEvent[] = [];

            game.events.forEach((e) => {
                if (e.id !== action.value) {
                    newEventsListAfterDeletion.push(e);
                }
            });

            newState = {
                ...game,
                events: newEventsListAfterDeletion
            };
            break;
        case GameDispatchActions.TEAM_COLOR_CHANGE:
            newState = {
                ...game,
                teams: game.teams.map((oldTeam) => {
                    if (oldTeam.id !== action.value.teamId) {
                        return oldTeam;
                    }

                    return {
                        ...oldTeam,
                        color: action.value.color
                    };
                })
            };
            break;
        case GameDispatchActions.GAME_HALF_TIME:
            newState = {
                ...game,
                isGameHalfTime: true
            };
            break;
        case GameDispatchActions.INITIAL_POPULATE:
            newState = {
                ...game,
                ...action.value
            };
            break;
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }

    if (game.matchId !== 0) {
        LocalStorageManager.setMatchData(game.matchId, newState);
    }

    return newState;
};
