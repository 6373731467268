import { Dispatch, createContext, useContext } from 'react';

import { GameDispatchActions, IGameContext, IGameDispatchContext } from '../types/InterfaceTypes';

export const initialGameState: IGameContext = {
    isGameStarted: false,
    isGameHalfTime: false,
    isGameSecondHalf: false,
    isGameCompleted: false,
    startTime: 0,
    secondHalfStartTime: 0,
    teams: [],
    isInsertMenuOpened: false,
    selectedPlayer: null,
    isShowingAllPlayers: false,
    events: [],
    matchId: 0,
    leagueId: 0,
    duration: 50,
    ballPossessionIndex: -1
    // TODO: add in DB -> isMatchCompleted
};

export const initialGameDispatchState: IGameDispatchContext = {
    type: GameDispatchActions.EMPTY,
    value: null
};

export const GameContext = createContext(initialGameState);
export const GameDispatchContext = createContext<Dispatch<IGameDispatchContext>>(() => null);

export function useGame() {
    return useContext(GameContext);
};

export function useGameDispatch() {
    return useContext(GameDispatchContext);
};
