import { Box, FormLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ShieldIcon from '@mui/icons-material/Shield';

import { GameDispatchActions, ITeam } from '../../types/InterfaceTypes';
import useTranslation from '../../hooks/useTranslation';
import { useGameDispatch } from '../../Providers/GameContext';

interface TeamEditSectionProps {
    team: ITeam;
}

export default function TeamEditSection(props: TeamEditSectionProps) {
    const { team } = props;
    const t = useTranslation();
    const dispatch = useGameDispatch();

    const availableColors = [
        {
            id: 'color1',
            label: 'Red',
            value: '#C00'
        },
        {
            id: 'color2',
            label: 'Green',
            value: '#3A4'
        },
        {
            id: 'color3',
            label: 'Blue',
            value: '#00C'
        },
        {
            id: 'color4',
            label: 'Yellow',
            value: '#E4D621'
        },
        {
            id: 'color5',
            label: 'Purple',
            value: '#D1D'
        },
        {
            id: 'color6',
            label: 'Orange',
            value: '#E57232'
        },
        {
            id: 'color7',
            label: 'White',
            value: '#FAFAFA'
        },
        {
            id: 'color8',
            label: 'Black',
            value: '#111'
        }
    ];

    const handleColorChange = (event: SelectChangeEvent) => {
        dispatch({
            type: GameDispatchActions.TEAM_COLOR_CHANGE,
            value: {
                teamId: team.id,
                color: event.target.value
            }
        });
    };

    return (
        <Box mb={3} p={2}>
            <Box mb={1}>
                <FormLabel id={`team-color-select-${team.id}`}>{t('teamColor')}</FormLabel>
            </Box>
            <Select
                value={team.color ?? '-1'}
                onChange={handleColorChange}
                variant='outlined'
                aria-label={t('teamColorMessage')}
                labelId={`team-color-select-${team.id}`}
            >
                <MenuItem value='-1'>
                    {t('noColor')}
                </MenuItem>
                {availableColors.map((color) => (
                    <MenuItem key={color.id} value={color.value}>
                        <ShieldIcon sx={{ color: color.value, mr: 1 }} />
                        {t(color.id as any)}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}
