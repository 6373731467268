import { useState } from 'react';
import {
    Box, Drawer, Fab, Grid, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import { useGame } from '../../Providers/GameContext';
import RecordedEventRow from './RecordedEventRow';
import useTranslation from '../../hooks/useTranslation';

export default function RecordedEventsList() {
    const { events } = useGame();
    const t = useTranslation();
    const [isPanelOpened, setIsPanelOpened] = useState(false);

    const handleOpen = () => {
        setIsPanelOpened(true);
    };

    const handleClose = () => {
        setIsPanelOpened(false);
    };

    return (
        <>
            <Fab
                className='events-button'
                size='medium'
                aria-label='Show recorded events'
                onClick={handleOpen}
            >
                <FormatListBulletedIcon />
            </Fab>
            <Drawer
                anchor='bottom'
                open={isPanelOpened}
                onClose={handleClose}
                PaperProps={{ sx: { px: '8px' } }}
            >
                <Grid container p={1} justifyContent='center'>
                    <Grid container item className='events-subtitle'>
                        <Typography variant='h4' color='primary'>{t('events')}</Typography>
                    </Grid>
                </Grid>

                {events.length === 0 && (
                    <Typography variant='caption' textAlign='center' mb={2}>{t('noEvents')}</Typography>
                )}

                {events.length !== 0 && (
                    <Box mb={1} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                        <TableContainer component={Paper}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Box className='flex-started-parent'>
                                                <AccessTimeIcon fontSize='small' />
                                            </Box>
                                        </TableCell>
                                        <TableCell>{t('event')}</TableCell>
                                        <TableCell>{t('team')}</TableCell>
                                        <TableCell>{t('player')}</TableCell>
                                        {/* <TableCell sx={{ textAlign: 'center' }}>{t('status')}</TableCell> */}
                                        <TableCell>
                                            <Box className='flex-centered-parent'>
                                                <DriveFileRenameOutlineOutlinedIcon fontSize='small' />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...events].reverse().map((event, index) => (
                                        <RecordedEventRow key={index} event={event} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Drawer>
        </>
    );
}
