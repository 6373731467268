import { useEffect, useState } from 'react';
import { LocalStorageManager, StorageKeysEnum } from '../common/LocalStorageManager';
import { IGameContext } from '../types/InterfaceTypes';

export const useStorage = <T>(key: keyof typeof StorageKeysEnum, getDefaultDataMethod: () => T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const initialValue = (LocalStorageManager.get(key) as T) ?? getDefaultDataMethod();
    const [data, setData] = useState<T>(initialValue);

    useEffect(() => {
        LocalStorageManager.set(key, data);
    }, [key, data]);

    return [data, setData];
};

export const useMatchStorage = (matchId: number): [IGameContext, React.Dispatch<React.SetStateAction<IGameContext>>] => {
    const initialValue = LocalStorageManager.getMatchData(matchId);
    const [data, setData] = useState(initialValue);

    useEffect(() => {
        if (matchId === 0 || JSON.stringify(data) === '{}') {
            return;
        }

        LocalStorageManager.setMatchData(matchId, data);
    }, [matchId, data]);

    return [data, setData];
};
