import { useState } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Grid
} from '@mui/material';

import { useGame, useGameDispatch } from '../../Providers/GameContext';
import { GameDispatchActions } from '../../types/InterfaceTypes';
import useTranslation from '../../hooks/useTranslation';
import Timer from './Timer';
import GameApi from '../../api/GameApi';
import { useNotificationsBarContext } from '../../Providers/NotificationsBarProvider';
import { isDevEnv } from '../../common/environmentHelper';
import { useSettingsProviderContext } from '../../Providers/SettingsProvider';

export default function ScoreBoard() {
    const { isGameStarted, isGameHalfTime, events, isGameCompleted, duration, teams, matchId } = useGame();
    const dispatch = useGameDispatch();
    const t = useTranslation();
    const { settings } = useSettingsProviderContext();
    const notify = useNotificationsBarContext();
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const isGamePaused = !isGameStarted || isGameHalfTime;
    const doAllTeamsHaveGoalkeeper = teams.every(t => t.players.some(p => p.goalkeeper));
    const goals = [0, 0];
    const team1Background = teams[0].color !== '-1' ? `linear-gradient(0deg, rgba(0,0,0,0) 60%, ${teams[0].color} 100%)` : undefined;
    const team2Background = teams[1].color !== '-1' ? `linear-gradient(0deg, rgba(0,0,0,0) 60%, ${teams[1].color} 100%)` : undefined;

    teams.forEach((team, index) => {
        goals[index] = events.filter((e) =>
            ((e.typeId === 9 || e.typeId === 11) && e.teamId === team.id)
            || (e.typeId === 6 && e.teamId !== team.id)
        ).length;
    });

    const onStartGameButtonClick = () => {
        if (!isGamePaused) {
            return;
        }

        setIsDialogOpened(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpened(false);
    };

    const handleDialogConfirm = async () => {
        handleDialogClose();

        if (!isGameStarted) {
            const requestMethod = settings.isTestMode ? GameApi.mockSuccessRequest : GameApi.startMatch;
            const startMatchResponse = await requestMethod(matchId);

            if (!isDevEnv()) {
                if (!startMatchResponse.isSuccess) {
                    notify.showNotification(t('somethingWentWrong'), 'error');

                    return;
                }
            }
        }
        else {
            const requestMethod = settings.isTestMode ? GameApi.mockSuccessRequest : GameApi.startSecondHalf;
            const startSecondHalfResponse = await requestMethod(matchId);

            if (!startSecondHalfResponse.isSuccess) {
                console.error('Second half start request failed');
            }
        }

        dispatch({ type: GameDispatchActions.START_TIMER });
    };

    if (isGameCompleted) {
        return (
            <Grid container px={1} justifyContent='space-between' alignItems='center'>
                <Grid item xs={2} className='score-number'>
                    {goals[0]}
                </Grid>
                <Grid item xs={6} textAlign='center'>
                    <Button variant='contained'>
                        {duration}:00
                    </Button>
                </Grid>
                <Grid item xs={2} className='score-number'>
                    {goals[1]}
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {/* {isGameStarted && (
                <BallPossession />
            )} */}

            <Grid container mb={1} px={1} justifyContent='space-between' alignItems='center'>
                <Grid item xs={2} className='score-number' sx={{ background: team1Background }}>
                    {goals[0]}
                </Grid>
                <Grid item xs={6} textAlign='center'>
                    <Button
                        variant='contained'
                        className='flex-centered-parent'
                        sx={{ flexDirection: 'column' }}
                        onClick={onStartGameButtonClick}
                        disabled={!doAllTeamsHaveGoalkeeper}
                    >
                        {!isGameStarted && (
                            <span>{t(isGameHalfTime ? 'continueGame' : 'startGame')}</span>
                        )}

                        <Timer />
                    </Button>
                </Grid>
                <Grid item xs={2} className='score-number' sx={{ background: team2Background }}>
                    {goals[1]}
                </Grid>
            </Grid>
            <Dialog open={isDialogOpened} onClose={handleDialogClose}>
                <DialogTitle>{t(!isGamePaused ? 'stopTimer' : 'startTimer')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(!isGamePaused ? 'stopTimerMessage' : 'startTimerMessage')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>{t('cancel')}</Button>
                    <Button onClick={handleDialogConfirm} autoFocus>{t('ok')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
