import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export interface ApiResponse<T> {
    data: T;
    isSuccess: boolean;
    errorMessage: string;
}

const basicRequest = async<T>(
    url: string,
    requestType: string,
    params?: any,
    isMultipart?: boolean,
    isFileDownload?: boolean
): Promise<ApiResponse<T>> => {
    let resultData: any = null;
    let resultIsSuccess: boolean = true;
    let resultErrorMessage: string = '';
    let configObject: AxiosRequestConfig<null> = {
        method: requestType,
        url: url,
        headers: undefined,
        data: params
    };

    if (requestType === 'getWithHeaders') {
        configObject.method = 'get';
        configObject.headers = params;
        delete configObject.data;
    }

    if (isMultipart) {
        configObject.headers = {
            'Content-Type': 'multipart/form-data'
        };
    }

    if (isFileDownload) {
        configObject.responseType = 'blob';
        configObject.headers = {
            'Content-Disposition': 'attachment;filename=issues.xlsx',
            'Content-Type': 'application/octet-stream'
        };
    }

    // let accessToken = '';

    // // Use the same publicClientApplication instance provided to MsalProvider
    // await msalInstance
    //     .acquireTokenSilent({
    //         scopes: loginRequest.scopes,
    //         account: msalInstance.getAllAccounts()[0]
    //     })
    //     .then(function (accessTokenResponse) {
    //         // Acquire token silent success
    //         accessToken = accessTokenResponse.accessToken;
    //     })
    //     .catch(function (error) {
    //         // Acquire token silent failure
    //         console.log(error);
    //     });

    // configObject.headers = {
    //     ...configObject.headers,
    //     'Authorization': 'Bearer ' + accessToken
    // };

    await axios(configObject)
        .then(function (response: any) {
            resultData = response.data.data;
            resultIsSuccess = response.data.isSuccess;
        })
        .catch(function (error: any) {
            resultIsSuccess = false;

            if (error instanceof AxiosError) {
                resultErrorMessage = error.message;
            }
            else {
                resultErrorMessage = error;
            }
        });

    const result = {
        data: resultData,
        isSuccess: resultIsSuccess,
        errorMessage: resultErrorMessage
    };

    return result;
};

const httpClient = {
    get: async<T>(url: string, params?: any): Promise<ApiResponse<T>> => {
        const request = await basicRequest(url, 'get', params) as ApiResponse<T>;

        return request;
    },
    getWithHeaders: async<T>(url: string, params?: any): Promise<ApiResponse<T>> => {
        const request = await basicRequest(url, 'getWithHeaders', params) as ApiResponse<T>;

        return request;
    },
    getResource: async<T>(url: string, query?: string): Promise<ApiResponse<T>> => {
        const request = await basicRequest(`${url}${query}`, 'get') as ApiResponse<T>;

        return request;
    },
    delete: async<T>(url: string, requestBody: any): Promise<ApiResponse<T>> => {
        const request = await basicRequest(url, 'delete', requestBody) as ApiResponse<T>;

        return request;
    },
    put: async<T>(url: string, requestBody: any): Promise<ApiResponse<T>> => {
        const request = await basicRequest(url, 'put', requestBody) as ApiResponse<T>;

        return request;
    },
    post: async<T>(url: string, requestBody: any, isMultipart?: boolean): Promise<ApiResponse<T>> => {
        const request = await basicRequest(url, 'post', requestBody, isMultipart) as ApiResponse<T>;

        return request;
    },
    patch: async<T>(url: string, requestBody: any): Promise<ApiResponse<T>> => {
        const request = await basicRequest(url, 'patch', requestBody) as ApiResponse<T>;

        return request;
    },
    downloadFile: async<T>(url: string, query: string): Promise<ApiResponse<T>> => {
        const request = await basicRequest(`${url}${query}`, 'get', undefined, false, true) as ApiResponse<T>;

        return request;
    }
};

export default httpClient;
