import { Avatar, Box, Checkbox, TableCell, TableRow } from '@mui/material';
import BackHandIcon from '@mui/icons-material/BackHand';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';

import { useGame, useGameDispatch } from '../../Providers/GameContext';
import { GameDispatchActions, IPlayer } from '../../types/InterfaceTypes';
import PlayerEditPopup from './PlayerEditPopup';

interface PreGamePlayerRowProps {
    player: IPlayer;
}

export default function PreGamePlayerRow(props: PreGamePlayerRowProps) {
    const { player } = props;
    const { events } = useGame();
    const playerEvents = events.filter((e) => e.playerId === player.id);
    const dispatch = useGameDispatch();

    const onRowClick = () => {
        if (player.selected && playerEvents.length > 0) {
            return;
        }

        dispatch({
            type: GameDispatchActions.PLAYER_CLICK,
            value: {
                playerId: player.id,
                teamId: player.teamId
            }
        });
    };

    const handleGoalkeeperChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch({
            type: GameDispatchActions.PLAYER_GOALKEEPER_EDIT,
            value: {
                playerId: player.id,
                teamId: player.teamId,
                isGoalkeeper: checked
            }
        });
    };

    return (
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: player.selected ? '#00B1EB' : undefined
            }}
            onClick={onRowClick}
        >
            <TableCell onClick={(e) => { e.stopPropagation(); }}>
                <PlayerEditPopup player={player} />
            </TableCell>
            <TableCell>
                <Avatar src={player.imageUrl} alt={player.name} className='player-picture' />
            </TableCell>
            <TableCell>
                <Box>{player.name}</Box>
                <Box>{player.birthDate}</Box>
            </TableCell>
            <TableCell>
                <Box className='flex-started-parent'>
                    {player.selected && (
                        <Checkbox
                            checked={!!player.goalkeeper}
                            onChange={handleGoalkeeperChange}
                            onClick={(e) => { e.stopPropagation(); }}
                            color='default'
                            icon={<BackHandOutlinedIcon />}
                            checkedIcon={<BackHandIcon sx={{ color: '#FFF' }} />}
                        />
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
}
