import { Button, Grid } from '@mui/material';

import useTranslation from '../../hooks/useTranslation';
import { useConfirmationWindowContext } from '../../Providers/ConfirmationWindowProvider';
import { LocalStorageManager } from '../../common/LocalStorageManager';
import { useSettingsProviderContext } from '../../Providers/SettingsProvider';
import { useGame } from '../../Providers/GameContext';

export default function TestModeSetting() {
    const { matchId } = useGame();
    const { settings } = useSettingsProviderContext();
    const t = useTranslation();
    const { showModal } = useConfirmationWindowContext();

    const handleResetConfirm = () => {
        LocalStorageManager.deleteMatchData(matchId);

        window.location.reload(); // TODO: use more elegant solution
    };

    const resetMatchProgress = () => {
        showModal(t('resetMatchProgress'), handleResetConfirm, t('resetMatchProgressMessage'));
    };

    if (!settings.isTestMode) {
        return null;
    }

    return (
        <Grid item xs={12} mb={1}>
            <Button variant='outlined' onClick={resetMatchProgress}>{t('resetMatchProgress')}</Button>
        </Grid>
    );
}
