import { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import useTranslation from '../../hooks/useTranslation';

export default function FullscreenAction() {
    const t = useTranslation();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const FullscreenButtonIcon = isFullscreen ? FullscreenExitIcon : FullscreenIcon;
    const buttonLabel = t(isFullscreen ? 'exitFullscreen' : 'enterFullscreen');

    const onButtonClick = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        else {
            document.body.requestFullscreen();
        }
    };

    useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(!!document.fullscreenElement);
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);

        // eslint-disable-next-line
    }, []);

    return (
        <Fab
            className='fullscreen-button'
            size='medium'
            aria-label={buttonLabel}
            onClick={onButtonClick}
        >
            <FullscreenButtonIcon />
        </Fab>
    );
}
