export const updateDarkModeStyles = (isDarkMode: boolean) => {
    document.body.classList.toggle('dark-mode', isDarkMode);

    const styleElement = document.head.querySelector('#custom-styles');

    if (styleElement) {
        styleElement.innerHTML = isDarkMode ? ':root { color-scheme: dark; }' : '';
    }
};

export const updateTestModeStyles = (isTestMode: boolean) => {
    document.body.classList.toggle('test-mode', isTestMode);
};
