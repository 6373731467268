import { useState } from 'react';
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogTitle, IconButton, InputLabel, TextField
} from '@mui/material';

import { GameDispatchActions, IPlayer } from '../../types/InterfaceTypes';
import { useGameDispatch } from '../../Providers/GameContext';
import useTranslation from '../../hooks/useTranslation';
import PlayerNumberModifiedIndicator from './PlayerNumberModifiedIndicator';

interface PlayerEditPopupProps {
    player: IPlayer;
}

export default function PlayerEditPopup(props: PlayerEditPopupProps) {
    const dispatch = useGameDispatch();
    const t = useTranslation();
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const [customNumber, setCustomNumber] = useState<string>(`${props.player.number}`);

    const onPlayerEditClick = () => {
        setIsDialogOpened(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpened(false);
        setCustomNumber(`${props.player.number}`);
    };

    const handleDialogConfirm = () => {
        setIsDialogOpened(false);

        let newNumber;

        if (customNumber !== '') {
            newNumber = parseInt(customNumber) || 0;
            newNumber = Math.max(newNumber, 0);
            newNumber = Math.min(newNumber, 99);
        }

        setCustomNumber(`${newNumber}`);

        dispatch({
            type: GameDispatchActions.PLAYER_NUMBER_EDIT,
            value: {
                playerId: props.player.id,
                teamId: props.player.teamId,
                customNumber: newNumber
            }
        });
    };

    const handleCustomNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCustomNumber(e.target.value);
    };

    return (
        <>
            <IconButton aria-label='edit' onClick={onPlayerEditClick} className='player-edit-button'>
                {props.player.number}
                <PlayerNumberModifiedIndicator player={props.player} />
            </IconButton>
            <Dialog open={isDialogOpened} onClose={handleDialogClose}>
                <DialogTitle>{t('editPlayer')}</DialogTitle>
                <DialogContent sx={{ minWidth: '270px' }}>
                    <Box mt={1} mb={2}>{t('originalNumber')}: {props.player.originalNumber}</Box>
                    <InputLabel sx={{ pt: 1 }}>
                        <Box>{t('playerNumber')}</Box>
                        <TextField
                            type='number'
                            fullWidth
                            size='small'
                            placeholder={t('changePlayerNumber')}
                            autoFocus
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                min: 0,
                                max: 99,
                                step: 1,
                                autoFocus: true
                            }}
                            value={customNumber}
                            onChange={handleCustomNumberChange}
                        />
                    </InputLabel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>{t('cancel')}</Button>
                    <Button onClick={handleDialogConfirm}>{t('ok')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
