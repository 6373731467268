import { useEffect } from 'react';
import { Box } from '@mui/material';

import { GameDispatchActions, IGameContext, IPlayer } from '../types/InterfaceTypes';
import GameApi from '../api/GameApi';
import CircularLoadingIndicator from '../common/CircularLoadingIndicator';
import { useMatchStorage } from '../hooks/useStorage';
import useTranslation from '../hooks/useTranslation';
import { initialGameState, useGame, useGameDispatch } from '../Providers/GameContext';
import { useNotificationsBarContext } from '../Providers/NotificationsBarProvider';
import Game from './Game/Game';
import ActionButtons from './Actions/ActionButtons';
import InsertEventMenu from './Events/InsertEventMenu';
import { useSettingsProviderContext } from '../Providers/SettingsProvider';
import TestModePanel from './TestModePanel';

export default function Home() {
    const { teams } = useGame();
    const dispatch = useGameDispatch();
    const notify = useNotificationsBarContext();
    const t = useTranslation();
    const { settings } = useSettingsProviderContext();

    const queryParameters = new URLSearchParams(window.location.search);
    const matchIdQueryParam = queryParameters.get('matchId');
    const matchIdFromServer = parseInt(matchIdQueryParam ?? '0');
    const [savedAppState] = useMatchStorage(matchIdFromServer);

    if (!matchIdFromServer) {
        notify.showNotification(t('noMatchId'), 'error');
    }

    const getMatchData = async () => {
        const matchData = await GameApi.getMatchData(matchIdFromServer);

        if (!matchData || !(matchData as any).isSuccess) {
            notify.showNotification(matchData.errorMessage ?? t('somethingWentWrong'), 'error');

            return;
        }

        const initialAppState: IGameContext = {
            ...initialGameState,
            ...savedAppState,
            matchId: matchIdFromServer,
            leagueId: matchData.data.leagueId,
            duration: matchData.data.duration,
            teams: matchData.data.teams.map((team) => {
                const savedTeam = savedAppState.teams?.find(t => t.id === team.id);
                const savedTeamPlayers = savedTeam?.players;

                const playersMapMethod = savedTeamPlayers ?
                    (p: IPlayer): IPlayer => {
                        const savedPlayer = savedTeamPlayers.find(pl => pl.id === p.id);

                        return {
                            ...p,
                            number: savedPlayer?.number ?? p.number,
                            originalNumber: p.number,
                            selected: savedPlayer?.selected,
                            goalkeeper: savedPlayer?.goalkeeper
                        };
                    } :
                    (p: IPlayer): IPlayer => {
                        return {
                            ...p,
                            originalNumber: p.number
                        };
                    };

                return {
                    ...team,
                    color: savedTeam?.color ?? '-1',
                    players: team.players
                        .map(playersMapMethod)
                        .sort((p1, p2) => p1.number - p2.number)
                };
            })
        };

        dispatch({
            type: GameDispatchActions.INITIAL_POPULATE,
            value: initialAppState
        });
    };

    useEffect(() => {
        if (!matchIdFromServer) {
            return;
        }

        getMatchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const onBeforeUnloadHandler = (e: any) => {
            e.preventDefault();

            return 'Really?!';
        };

        window.addEventListener('beforeunload', onBeforeUnloadHandler);

        return () => window.removeEventListener('beforeunload', onBeforeUnloadHandler);
    }, []);

    return (
        <Box className='page-wrapper'>
            {settings.isTestMode && (
                <TestModePanel />
            )}

            {teams.length === 0 && (
                <CircularLoadingIndicator />
            )}

            {teams.length !== 0 && (
                <Game />
            )}

            <ActionButtons />

            <InsertEventMenu />
        </Box>
    );
}
