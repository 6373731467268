import { useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import useTranslation from '../hooks/useTranslation';
import { LocalStorageManager } from '../common/LocalStorageManager';
import { useGame } from '../Providers/GameContext';

export default function TestModePanel() {
    const { matchId } = useGame();
    const t = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleButtonClick = () => {
        LocalStorageManager.deleteMatchData(matchId);

        // TODO: hard-coded URL - bad...
        window.location.replace('https://livescore.amfl-bg.com/admin/matchs');
    };

    const handleTogglePanelButtonClick = () => {
        setIsCollapsed(c => !c);
    };

    return (
        <Box className={`test-mode-panel ${isCollapsed ? 'collapsed' : ''}`}>
            <Box component='span' mr={2}>{t('testMode')}</Box>
            <Button onClick={handleButtonClick} variant='contained'>{t('exit')}</Button>
            <IconButton
                aria-label='toggle'
                className='test-mode-collapse-button'
                onClick={handleTogglePanelButtonClick}
            >
                {isCollapsed ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
            </IconButton>
        </Box >
    );
}
