import { Box, Divider, Grid, Typography } from '@mui/material';

import { ITeam } from '../../types/InterfaceTypes';
import Player from '../Player/Player';
import { useGame } from '../../Providers/GameContext';
import EventItemButton from '../Events/EventItemButton';
import { teamEventsListSchema } from '../../common/testValues';
import { getEventsCountPerTeam } from '../../common/eventHelper';

interface TeamProps {
    team: ITeam;
    index: number;
}

export default function Team(props: TeamProps) {
    const { team } = props;
    const { isGameStarted, isShowingAllPlayers, events } = useGame();
    const eventCountsMap = getEventsCountPerTeam(team.id, events);
    const players = isGameStarted && !isShowingAllPlayers ?
        team.players
            .filter(p => p.selected)
            .sort((p1, p2) => {
                if (p1.goalkeeper && !p2.goalkeeper) {
                    return -1;
                }
                else if (!p1.goalkeeper && p2.goalkeeper) {
                    return 1;
                }

                return p1.number - p2.number;
            }) :
        team.players;
    const teamBackground = team.color !== '-1' ? (
        props.index === 0 ?
            `linear-gradient(90deg, rgba(0,0,0,0) 40%, ${team.color} 100%)` :
            `linear-gradient(90deg, ${team.color} 0%, rgba(0,0,0,0) 60%)`
    ) : undefined;

    return (
        <Box>
            <Typography variant='h4' color='primary' className='team-name'>{team.name}</Typography>
            <Grid container className='team-event-buttons-wrapper'>
                {teamEventsListSchema.map((eventItem) => (
                    <EventItemButton
                        key={eventItem.id}
                        eventItem={eventItem}
                        team={team}
                        cellSize={5.75}
                        eventCount={eventCountsMap.get(eventItem.id)}
                    />
                ))}
            </Grid>
            <Divider variant='middle' sx={{ my: 1 }} />
            <Box py={1} sx={{ background: teamBackground }}>
                {players.map((player) => (
                    <Player key={player.id} player={player} />
                ))}
            </Box>
        </Box>
    );
}
