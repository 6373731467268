import { useGame } from '../../Providers/GameContext';
import ScoreBoard from './ScoreBoard';
import InGameScreen from './InGameScreen';
import PreGameScreen from './PreGameScreen';
import PostGameScreen from './PostGameScreen';

export default function Game() {
    const { isGameStarted, isGameCompleted, isShowingAllPlayers } = useGame();

    return (
        <>
            <ScoreBoard />

            {(!isGameStarted || isShowingAllPlayers) && !isGameCompleted && (
                <PreGameScreen />
            )}

            {isGameStarted && !isShowingAllPlayers && !isGameCompleted && (
                <InGameScreen />
            )}

            {isGameCompleted && (
                <PostGameScreen />
            )}
        </>
    );
}
