export const getEnvironmentVariable = (key: string) => {
    try {
        if (!process.env || typeof process.env !== 'object') {
            return;
        }
    }
    catch (e) {
        return '';
    }

    return process.env[key] ?? '';
};

export const isDevEnv = () => {
    const nodeEnvValue = getEnvironmentVariable('NODE_ENV');

    return nodeEnvValue === 'development';
};

export const getAppVersion = () => {
    const appVersionValue = getEnvironmentVariable('REACT_APP_VERSION');

    return appVersionValue;
};
