import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useGame, useGameDispatch } from '../../Providers/GameContext';
import { formatSeconds, getElapsedTimeFromStart } from '../../common/timeHelper';
import { GameDispatchActions } from '../../types/InterfaceTypes';
import { useNotificationsBarContext } from '../../Providers/NotificationsBarProvider';
import useTranslation from '../../hooks/useTranslation';

export default function Timer() {
    const { isGameStarted, isGameHalfTime, isGameSecondHalf, isGameCompleted, matchId, duration } = useGame();
    const dispatch = useGameDispatch();
    const notify = useNotificationsBarContext();
    const t = useTranslation();
    const elapsedTimeFromStart = getElapsedTimeFromStart(matchId, duration);
    const [elapsedTime, setElapsedTime] = useState(elapsedTimeFromStart);

    useEffect(() => {
        const fullTime = duration * 60;
        const halfTime = fullTime / 2;
        const gameTimer = setInterval(() => {
            if (!isGameStarted || isGameHalfTime || isGameCompleted) {
                return;
            }

            if (elapsedTime >= fullTime) {
                clearInterval(gameTimer);

                setElapsedTime(fullTime);

                notify.showNotification(t('fullTime'), 'success');

                return;
            }

            const elapsedTimeFromStart = getElapsedTimeFromStart(matchId, duration);

            setElapsedTime(elapsedTimeFromStart);

            if (!isGameSecondHalf && elapsedTime >= halfTime) {
                setElapsedTime(halfTime);
                dispatch({ type: GameDispatchActions.GAME_HALF_TIME });
                notify.showNotification(t('halfTime'), 'success');
            }
        }, 997);

        return () => clearInterval(gameTimer);

        // eslint-disable-next-line
    }, [isGameStarted, isGameHalfTime, isGameCompleted, elapsedTime, matchId, duration]);

    return (
        <Box sx={{ minWidth: '30px' }}>{formatSeconds(elapsedTime)}</Box>
    );
}
