import { ReactNode } from 'react';

export interface IParticipatingPlayer {
    id: number;
    teamId: number;
    position?: number;
};

export interface IPlayer extends IParticipatingPlayer {
    name: string;
    nickname: string;
    birthDate?: string;
    number: number;
    imageUrl?: string;
    selected?: boolean;
    goalkeeper?: boolean;
    originalNumber?: number;
};

export interface ITeam {
    id: number;
    name: string;
    players: IPlayer[];
    color?: string;
    possessionSeconds: number;
};

export interface IMatch {
    id: number;
    leagueId: number;
    teams: ITeam[];
    duration: number;
}

export interface IEventType {
    id: number;
    name: string;
};

export interface IBasicEvent {
    typeId: number;
    matchId: number;
    leagueId: number;
    teamId: number;
    playerId: IPlayer['id'];
};

export interface IEvent extends IBasicEvent {
    id: number;
    minute: number;
    status: EventUploadStatus;
};

export type INonMinuteEventIds = 1 | 2 | 3 | 4 | 5 | 10 | 12 | 14 | 16;

export interface INonMinuteEvent extends IBasicEvent {
    typeId: INonMinuteEventIds;
    sum: number;
};

export interface IGameContext {
    isGameStarted: boolean;
    isGameHalfTime: boolean;
    isGameSecondHalf: boolean;
    isGameCompleted: boolean;
    startTime: number;
    secondHalfStartTime: number;
    teams: ITeam[];
    isInsertMenuOpened: boolean;
    selectedPlayer: IPlayer | null;
    isShowingAllPlayers: boolean;
    events: IEvent[];
    matchId: number;
    leagueId: number;
    duration: number;
    ballPossessionIndex: number;
};

export interface IGameDispatchContext {
    type: GameDispatchActions;
    value?: any;
};

export enum GameDispatchActions {
    EMPTY = 'EMPTY',
    INITIAL_POPULATE = 'INITIAL_POPULATE',
    PLAYER_CLICK = 'PLAYER_CLICK',
    START_TIMER = 'START_TIMER',
    STOP_TIMER = 'STOP_TIMER',
    OPEN_INSERT_MENU = 'OPEN_INSERT_MENU',
    CLOSE_INSERT_MENU = 'CLOSE_INSERT_MENU',
    TOGGLEDISPLAYALLPLAYERS = 'TOGGLEDISPLAYALLPLAYERS',
    ADD_EVENT = 'ADD_EVENT',
    EVENT_UPLOAD_SUCCEEDED = 'EVENT_UPLOADED',
    EVENT_UPLOAD_IN_PROGRESS = 'EVENT_UPLOAD_IN_PROGRESS',
    EVENT_UPLOAD_FAILED = 'EVENT_UPLOAD_FAILED',
    GAME_COMPLETED = 'GAME_COMPLETED',
    PLAYER_NUMBER_EDIT = 'PLAYER_NUMBER_EDIT',
    PLAYER_GOALKEEPER_EDIT = 'PLAYER_GOALKEEPER_EDIT',
    BALL_POSSESSION_CHANGE = 'BALL_POSSESSION_CHANGE',
    DELETE_EVENT = 'DELETE_EVENT',
    GAME_HALF_TIME = 'GAME_HALF_TIME',
    EDIT_EVENT = 'EDIT_EVENT',
    TEAM_COLOR_CHANGE = 'TEAM_COLOR_CHANGE'
};

export interface ChildrenProps {
    children: ReactNode;
};

export enum EventUploadStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL'
};

export interface SettingsFields {
    isDarkMode: boolean;
    language: string;
    isTestMode: boolean;
};

export interface ITeamAssessment {
    teamId: ITeam['id']
    score: number;
    halfTimeScore: number;
    posts: number; // crossbars
    corners: number;
    kits: number | '', // екипи
    fairplay: number | '',
    numbers: number | '',
    photos: number | '',
    delay: number | '',
    playerOfTheMatch: IPlayer['id'] | '',
    cleanSheet: IPlayer['id'][]
};

export interface ICompleteMatchData {
    matchId: IMatch['id'];
    leagueId: number; // служебно поле
    teams: ITeamAssessment[];
    nonMinuteEvents: INonMinuteEvent[];
    minuteEvents: INonMinuteEvent[];
    participatingPlayers: IParticipatingPlayer[];
    testMatchData?: string;
};
