import { createContext, useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { ChildrenProps } from '../types/InterfaceTypes';
import useTranslation from '../hooks/useTranslation';

const ConfirmationWindowContext = createContext({
    showModal: (
        modalTitle: string,
        handleConfirmation: () => void,
        modalText?: string,
        textConfirm?: string,
        textCancel?: string
    ) => { }
});

export const useConfirmationWindowContext = () => useContext(ConfirmationWindowContext);

export default function ConfirmationWindowProvider(props: ChildrenProps) {
    const t = useTranslation();
    const okText = t('ok');
    const cancelTextResource = t('cancel');
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState(`${t('confirm')}?`);
    const [text, setText] = useState('');
    const [cancelText, setCancelText] = useState(cancelTextResource);
    const [confirmText, setConfirmText] = useState(okText);
    const [confirmMethod, setConfirmMethod] = useState<() => void>(() => { });

    const showModal = (
        modalTitle: string,
        handleConfirmation: () => void,
        modalText?: string,
        textConfirm?: string,
        textCancel?: string
    ) => {
        setConfirmMethod(() => { return handleConfirmation; });
        setTitle(modalTitle);
        setText(modalText || '');
        setConfirmText(textConfirm || okText);
        setCancelText(textCancel || cancelTextResource);

        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleConfirmInner = () => {
        confirmMethod();

        setIsOpen(false);
    };

    const renderComponent = () => {
        return (
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                {text && (
                    <DialogContent>
                        <DialogContentText>{text}</DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose}>{cancelText}</Button>
                    <Button onClick={handleConfirmInner} autoFocus>{confirmText}</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <ConfirmationWindowContext.Provider value={{ showModal }}>
            {props.children}
            {renderComponent()}
        </ConfirmationWindowContext.Provider>
    );
}
