import { useReducer } from 'react';

import { ChildrenProps } from '../types/InterfaceTypes';
import { GameContext, GameDispatchContext, initialGameState } from './GameContext';
import { gameReducer } from './gameReducer';

export function GameProvider({ children }: ChildrenProps) {
    const [gameState, dispatch] = useReducer(gameReducer, initialGameState);

    return (
        <GameContext.Provider value={gameState}>
            <GameDispatchContext.Provider value={dispatch}>
                {children}
            </GameDispatchContext.Provider>
        </GameContext.Provider>
    );
}
