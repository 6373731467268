import { Fab } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

import { GameDispatchActions } from '../../types/InterfaceTypes';
import { useGame, useGameDispatch } from '../../Providers/GameContext';
import useTranslation from '../../hooks/useTranslation';

export default function TogglePlayersAction() {
    const { isShowingAllPlayers, teams } = useGame();
    const dispatch = useGameDispatch();
    const t = useTranslation();
    const buttonLabel = t(isShowingAllPlayers ? 'hidePlayers' : 'showPlayers');
    const doAllTeamsHaveGoalkeeper = teams.every(t => t.players.some(p => p.goalkeeper));

    const onButtonClick = () => {
        dispatch({ type: GameDispatchActions.TOGGLEDISPLAYALLPLAYERS });
    };

    return (
        <Fab
            className='players-button'
            size='medium'
            aria-label={buttonLabel}
            color={isShowingAllPlayers ? 'primary' : 'default'}
            onClick={onButtonClick}
            disabled={isShowingAllPlayers && !doAllTeamsHaveGoalkeeper}
        >
            <GroupIcon />
        </Fab>
    );
}
