import { ChangeEvent, useEffect } from 'react';
import { Grid, InputLabel, Switch } from '@mui/material';

import { updateDarkModeStyles } from './settingsHelper';
import { useSettingsProviderContext } from '../../Providers/SettingsProvider';
import { useStorage } from '../../hooks/useStorage';
import { StorageKeysEnum } from '../../common/LocalStorageManager';
import useTranslation from '../../hooks/useTranslation';

export default function DarkModeSetting() {
    const { updateSettings } = useSettingsProviderContext();
    const t = useTranslation();
    const [isDarkMode, setIsDarkMode] = useStorage<boolean>(StorageKeysEnum.isDarkMode, () => false);
    const darkModeText = t('darkMode');

    const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsDarkMode(checked);
    };

    useEffect(() => {
        updateSettings('isDarkMode', isDarkMode);

        updateDarkModeStyles(isDarkMode);

        // eslint-disable-next-line
    }, [isDarkMode]);

    return (
        <Grid item xs={12} mb={1}>
            <InputLabel>
                <Switch checked={isDarkMode} onChange={handleChange} name={darkModeText} />
                <span>{darkModeText}</span>
            </InputLabel>
        </Grid>
    );
}
