import { ChangeEvent, useState } from 'react';
import {
    Badge, Box, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, Divider,
    ListSubheader, MenuItem, Select, TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { GameDispatchActions, IEvent } from '../../types/InterfaceTypes';
import useTranslation from '../../hooks/useTranslation';
import { useConfirmationWindowContext } from '../../Providers/ConfirmationWindowProvider';
import { eventTypes, nonMinuteEventIds, penaltyEventIds } from '../../common/testValues';
import { useGame, useGameDispatch } from '../../Providers/GameContext';
import { getEventIcon } from '../../common/eventHelper';
import GameApi from '../../api/GameApi';
import { useNotificationsBarContext } from '../../Providers/NotificationsBarProvider';
import { useSettingsProviderContext } from '../../Providers/SettingsProvider';

interface EventEditPopupProps {
    event: IEvent;
    handleDialogClose: () => void;
}

export default function EventEditPopup(props: EventEditPopupProps) {
    const { event } = props;
    const { teams, duration } = useGame();
    const dispatch = useGameDispatch();
    const t = useTranslation();
    const { showModal } = useConfirmationWindowContext();
    const notify = useNotificationsBarContext();
    const { settings } = useSettingsProviderContext();
    const [customMinute, setCustomMinute] = useState<string>(`${event.minute}`);
    const [eventTypeId, setEventTypeId] = useState(event.typeId);
    const [playerId, setPlayerId] = useState<number | ''>(event.playerId);
    const isNonMinuteEvent = nonMinuteEventIds.indexOf(event.typeId as any) !== -1;
    const isNonPlayerEvent = eventTypeId === 4 || eventTypeId === 14;

    const handleDialogConfirm = async () => {
        props.handleDialogClose();

        const isNonPlayerEvent = eventTypeId === 4 || eventTypeId === 14;
        const parsedMinute = parseInt(customMinute) ?? 1;
        const newEvent: IEvent = {
            ...event,
            minute: Math.min(parsedMinute, duration),
            typeId: eventTypeId,
            playerId: (isNonPlayerEvent || !playerId) ? 0 : playerId,
            teamId: teams.find(team => team.players.some(p => p.id === playerId))?.id ?? event.teamId
        };

        if (isNonMinuteEvent) {
            dispatch({ type: GameDispatchActions.EDIT_EVENT, value: newEvent });
            return;
        }

        const requestMethod = settings.isTestMode ? GameApi.mockSuccessRequest : GameApi.editEvent;
        const editEventResponse = await requestMethod(newEvent);

        if (!editEventResponse.isSuccess || !editEventResponse.data) {
            notify.showNotification(t('somethingWentWrong'), 'error');

            return;
        }

        dispatch({ type: GameDispatchActions.EDIT_EVENT, value: newEvent });

        notify.showNotification(t('eventUpdatedSuccessfully'), 'success');
    };

    const onDeleteConfirm = async () => {
        if (isNonMinuteEvent) {
            dispatch({ type: GameDispatchActions.DELETE_EVENT, value: event.id });

            props.handleDialogClose();

            return;
        }

        const requestMethod = settings.isTestMode ? GameApi.mockSuccessRequest : GameApi.deleteEvent;
        const deleteEventResponse = await requestMethod(event);

        if (!deleteEventResponse.isSuccess) {
            notify.showNotification(t('somethingWentWrong'), 'error');

            return;
        }

        props.handleDialogClose();

        dispatch({ type: GameDispatchActions.DELETE_EVENT, value: event.id });

        notify.showNotification(t('eventDeletedSuccessfully'), 'success');
    };

    const handleDeleteButtonClick = () => {
        showModal(t('deleteEvent'), onDeleteConfirm, t('deleteEventMessage'));
    };

    const handleCustomMinuteChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedMinute = Math.abs(parseInt(e.target.value));

        setCustomMinute(`${isNaN(parsedMinute) ? '' : parsedMinute}`);
    };

    return (
        <Dialog open={true} onClose={props.handleDialogClose}>
            <DialogTitle>{t('editEvent')}</DialogTitle>
            <DialogContent sx={{ minWidth: '270px' }}>
                <Box my={2}>
                    <Box mr={1} fontSize='14px'>{t('minute')}</Box>
                    <Box>
                        <TextField
                            type='number'
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                min: 1,
                                max: duration,
                                step: 1
                            }}
                            size='small'
                            value={customMinute}
                            onChange={handleCustomMinuteChange}
                        />
                    </Box>
                </Box>
                <Box mb={2}>
                    <Box mr={1} fontSize='14px'>{t('event')}</Box>
                    <Box>
                        <Select
                            value={eventTypeId}
                            onChange={(e) => { setEventTypeId(e.target.value as number); }}
                            sx={{ minWidth: '200px' }}
                        >
                            {eventTypes.filter(et => et.id !== 12 && et.id !== 16).map((et) => {
                                const eventIcon = getEventIcon(et.id);
                                const badgeContent = penaltyEventIds.includes(et.id) ? 'P' : 0;

                                return (
                                    <MenuItem key={et.id} value={et.id}>
                                        <Badge badgeContent={badgeContent} color='primary'>
                                            <Box mr={1} className='flex-centered-parent' component='span'>{eventIcon}</Box>
                                            {t(`event${et.id}` as any)}
                                        </Badge>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                </Box>
                <Box mb={2}>
                    <Box mr={1} fontSize='14px'>{t('player')}</Box>
                    <Box>
                        <Select
                            value={playerId}
                            onChange={(e) => { setPlayerId(e.target.value as number); }}
                            sx={{ minWidth: '200px' }}
                            disabled={isNonPlayerEvent}
                        >
                            {teams.map((team) => {
                                return [
                                    <ListSubheader key={team.id}>{team.name}</ListSubheader>,
                                    team.players.map((player) => (
                                        <MenuItem key={player.id} value={player.id}>
                                            <Box component='span' mr={1}>{player.number}</Box>
                                            {player.nickname}
                                        </MenuItem>
                                    ))
                                ];
                            })}
                        </Select>
                    </Box>
                </Box>
                <Divider variant='middle' sx={{ my: 2 }} />
                <Box>
                    <Button
                        variant='outlined'
                        color='error'
                        onClick={handleDeleteButtonClick}
                        startIcon={<DeleteIcon />}
                    >
                        {t('delete')}
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleDialogClose}>{t('cancel')}</Button>
                <Button
                    onClick={handleDialogConfirm}
                    disabled={!playerId && eventTypeId !== 4 && eventTypeId !== 14}
                >
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
