import { Box, FormLabel, Grid, MenuItem, Select } from '@mui/material';

import { ITeam, ITeamAssessment } from '../../types/InterfaceTypes';
import useTranslation from '../../hooks/useTranslation';
import { assessmentKeys } from '../../common/testValues';

interface TeamReviewSectionProps {
    team: ITeam;
    teamAssessment: ITeamAssessment;
    handleChange: (id: any, value: any) => void;
}

export default function TeamReviewSection(props: TeamReviewSectionProps) {
    const { team } = props;
    const t = useTranslation();

    return (
        <>
            <Grid container justifyContent='space-between'>
                {assessmentKeys.map((key) => {
                    const value = props.teamAssessment[key];

                    return (
                        <Grid item key={key} mb={1}>
                            <Box>
                                <FormLabel>{t(`${key}Title` as any)}</FormLabel>
                            </Box>
                            <Box>
                                <Select
                                    value={value}
                                    onChange={(e) => { props.handleChange(key, e.target.value); }}
                                    variant='outlined'
                                    aria-label={t(`${key}Title` as any)}
                                    size='small'
                                    sx={{ backgroundColor: '#C1DAFF' }}
                                    SelectDisplayProps={{
                                        className: 'review-section-dropdown'
                                    }}
                                >
                                    <MenuItem value=''>
                                        {t('selectPlaceholder')}
                                    </MenuItem>
                                    {[0, 1, 2, 3].map((score) => (
                                        <MenuItem key={score} value={score}>
                                            {score}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                    );
                })}

                <Grid item mb={1}>
                    <Box>
                        <FormLabel>{t('playerOfTheMatch')}</FormLabel>
                    </Box>
                    <Box>
                        <Select
                            value={props.teamAssessment.playerOfTheMatch}
                            onChange={(e) => { props.handleChange('playerOfTheMatch', e.target.value); }}
                            variant='outlined'
                            aria-label={t('playerOfTheMatch')}
                            size='small'
                            SelectDisplayProps={{
                                className: 'review-section-dropdown'
                            }}
                        >
                            {team.players.filter(p => p.selected).map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.number} {p.nickname}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
            </Grid>
            <Box mb={1}>
                <Box>
                    <FormLabel>{t('cleanSheet')}</FormLabel>
                </Box>
                <Box>
                    <Select
                        multiple
                        value={props.teamAssessment.cleanSheet}
                        onChange={(e) => {
                            props.handleChange('cleanSheet', typeof e.target.value === 'string' ? [] : e.target.value);
                        }}
                        variant='outlined'
                        aria-label={t('cleanSheet')}
                        size='small'
                        sx={{ minWidth: '240px', width: '100%' }}
                        SelectDisplayProps={{
                            className: 'review-section-dropdown'
                        }}
                    >
                        {team.players.filter(p => p.selected).map((p) => (
                            <MenuItem key={p.id} value={p.id}>
                                {`${p.number} ${p.nickname}${p.goalkeeper ? ' [GK] ' : ''}`}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>
        </>
    );
}
