import { LangResources } from '../types/LanguageType';

export const bgBG: LangResources = {
    language: 'Език',
    settings: 'Настройки',
    darkMode: 'Тъмен режим',
    testMode: 'Тестов режим',
    confirm: 'Потвърди',
    cancel: 'Отказ',
    ok: 'Ок',
    penaltyRelated: 'От дузпа',
    failedTryAgain: 'Грешка, опитай отново',
    deleteEvent: 'Изтрий събитието?',
    deleteEventMessage: 'Сигурен ли си, че искаш да изтриеш събитието?',
    events: 'Събития',
    noEvents: 'Няма записани събития',
    event: 'Събитие',
    player: 'Играч',
    status: 'Статус',
    actions: 'Действия',
    startGame: 'Започни мача',
    continueGame: 'Продължи мача',
    stopGame: 'Спри мача',
    startTimer: 'Пусни таймера',
    startTimerMessage: 'Сигурен ли си, че искаш да пуснеш таймера?',
    stopTimer: 'Спри таймера',
    stopTimerMessage: 'Сигурен ли си, че искаш да спреш таймера?',
    editMinute: 'Редактирай изминалите минути',
    changePlayerNumber: 'Смени номера на играча',
    event1: 'Точен',
    event2: 'Неточен',
    event3: 'Асистенция',
    event4: 'Греда',
    event5: 'Фал',
    event6: 'Автогол',
    event7: 'Жълт картон',
    event8: 'Червен картон',
    event9: 'Гол',
    event10: 'Спасяване',
    event11: 'Гол',
    event12: 'Спасяване',
    event13: 'Пропуск',
    event14: 'Ъглов',
    event15: '2-ри жълт',
    event16: 'Удар + Спасяване',
    edit: 'Редактирай',
    delete: 'Изтрий',
    enterFullscreen: 'Излез от цял екран',
    exitFullscreen: 'Покажи на цял екран',
    showPlayers: 'Покажи всички играчи',
    hidePlayers: 'Скрий неприсъстващите играчи',
    editPlayer: 'Редактирай играч',
    goalkeeper: 'Вратар',
    changePlayerNickname: 'Смени прякора на играча',
    noMatchId: 'Невалиден идентификатор на мач',
    did: 'направи',
    playerNumber: 'Номер на играч',
    playerNickname: 'Прякор на играч',
    teamColor: 'Цвят на отбора',
    teamColorMessage: 'Избери цвят на отбора',
    number: 'Номер',
    picture: 'Снимка',
    name: 'Име',
    noColor: 'Без цвят',
    color1: 'Червен',
    color2: 'Зелен',
    color3: 'Син',
    color4: 'Жълт',
    color5: 'Лилав',
    color6: 'Оранжев',
    color7: 'Бял',
    color8: 'Черен',
    undo: 'Отмени',
    ballPossession: 'Владение на топката',
    ballNeutral: 'Ничия',
    has: 'има',
    fouls: 'нарушения',
    team: 'Отбор',
    editEvent: 'Редактирай събитие',
    minute: 'Минута',
    endGame: 'Завърши мача',
    endGameMessage: 'Сигурен ли си, че искаш да завършиш мача?',
    kitsTitle: 'Екипи',
    fairplayTitle: 'Спортментство',
    numbersTitle: 'Номера',
    photosTitle: 'Снимки',
    delayTitle: 'Закъснение',
    playerOfTheMatch: 'Играч на мача',
    cleanSheet: 'Чиста мрежа',
    submitGame: 'Изпрати отчет',
    submitGameMessage: 'Сигурен ли си, че искаш да изпратиш отчет за мача?',
    halfTime: 'Полувреме',
    fullTime: 'Пълно време',
    somethingWentWrong: 'Нещо се обърка...',
    matchSavedSuccessfully: 'Мачът е запазен успешно',
    eventUndone: 'Събитието е отменено',
    eventUpdatedSuccessfully: 'Събитието е обновено',
    eventDeletedSuccessfully: 'Събитието е изтрито',
    selectPlaceholder: 'Избери...',
    originalNumber: 'Стандартен номер',
    resetMatchProgress: 'Рестартирай прогреса на мача',
    resetMatchProgressMessage: 'Сигурен ли си, че искаш да рестартираш прогреса на мача? Това ще изтрие всички локални промени.',
    exit: 'Излез',
};
