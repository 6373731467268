import { Badge, Button, Grid } from '@mui/material';

import { EventUploadStatus, GameDispatchActions, IEvent, IEventType, IPlayer, ITeam } from '../../types/InterfaceTypes';
import { LangResources } from '../../types/LanguageType';
import { getEventIcon, saveBackup } from '../../common/eventHelper';
import { nonMinuteEventIds, numberOfFoulsThreshold } from '../../common/testValues';
import useTranslation from '../../hooks/useTranslation';
import { useGame, useGameDispatch } from '../../Providers/GameContext';
import { useNotificationsBarContext } from '../../Providers/NotificationsBarProvider';
import { getElapsedTimeFromStart, getMinuteFromSeconds } from '../../common/timeHelper';
import GameApi from '../../api/GameApi';
import { useSettingsProviderContext } from '../../Providers/SettingsProvider';

interface EventItemButtonProps {
    eventItem: IEventType;
    cellSize: number;
    team?: ITeam;
    eventCount?: number;
}

export default function EventItemButton(props: EventItemButtonProps) {
    const {
        selectedPlayer, matchId,
        leagueId, events, duration,
        isGameStarted, isGameCompleted
    } = useGame();
    const dispatch = useGameDispatch();
    const t = useTranslation();
    const notify = useNotificationsBarContext();
    const { settings } = useSettingsProviderContext();
    let eventTypeId = (props.eventItem.id === 7 && events.some((e) => e.typeId === 7 && e.playerId === selectedPlayer?.id)) ?
        15 :
        props.eventItem.id;
    const translatedEventName = t(`event${eventTypeId}` as keyof LangResources);
    const eventDoer: IPlayer | null = props.team ? {
        id: 0,
        teamId: props.team.id,
        name: props.team.name,
        nickname: props.team.name,
        number: 0
    } : selectedPlayer;
    const buttonIcon = getEventIcon(props.eventItem.id);
    let buttonClassName = 'events-list-button';

    if (eventTypeId === 4 || eventTypeId === 14) {
        buttonClassName = `${buttonClassName} small-events-list-button`;
    }

    if (eventTypeId === 16) {
        buttonClassName = `${buttonClassName} shot-and-save-event-button`;
    }

    const handleUndo = (eventId: IEvent['id']) => {
        dispatch({ type: GameDispatchActions.DELETE_EVENT, value: eventId });

        notify.showNotification(t('eventUndone'), 'info');
    };

    const checkFouls = (currentSelectedPlayer: IPlayer | null, oldEvents: IEvent[]) => {
        if (!currentSelectedPlayer) {
            return;
        }

        let numberOfOldFouls = 0;

        oldEvents.forEach((e) => {
            if (e.playerId !== currentSelectedPlayer.id) {
                return;
            }

            if (e.typeId === 7) {
                numberOfOldFouls = 0;

                return;
            }

            if (e.typeId === 5) {
                numberOfOldFouls++;
            }
        });

        // adding the new foul
        numberOfOldFouls += 1;

        if (numberOfOldFouls >= numberOfFoulsThreshold - 1) {
            setTimeout(() => {
                const foulsMessage = `${t('player')} [${currentSelectedPlayer.number}] ${currentSelectedPlayer.nickname} ${t('has')} ${numberOfOldFouls} ${t('fouls')}`;

                notify.showNotification(foulsMessage, 'warning');
            }, 2000);
        }
    };

    const saveEvent = async (eventToUpload: IEvent) => {
        dispatch({ type: GameDispatchActions.EVENT_UPLOAD_IN_PROGRESS, value: eventToUpload.id });

        const requestMethod = settings.isTestMode ? GameApi.mockSuccessRequest : GameApi.saveEvent;
        const saveEventResponse = await requestMethod(eventToUpload);

        if (!saveEventResponse.isSuccess || !saveEventResponse.data) {
            dispatch({ type: GameDispatchActions.EVENT_UPLOAD_FAILED, value: eventToUpload.id });

            notify.showNotification(saveEventResponse.errorMessage ?? t('somethingWentWrong'), 'error');

            return;
        }

        dispatch({
            type: GameDispatchActions.EVENT_UPLOAD_SUCCEEDED,
            value: {
                id: saveEventResponse.data.id,
                oldInternalId: eventToUpload.id
            }
        });
    };

    const onButtonClick = async () => {
        if (!eventDoer) {
            return;
        }

        const elapsedTime = getElapsedTimeFromStart(matchId, duration);
        const eventInternalId = Date.now();
        const isNonMinuteEvent = nonMinuteEventIds.indexOf(eventTypeId as any) !== -1;
        const eventToUpload: IEvent = {
            id: eventInternalId,
            typeId: eventTypeId,
            matchId: matchId,
            leagueId: leagueId,
            teamId: eventDoer.teamId ?? -1,
            playerId: eventDoer.id,
            minute: getMinuteFromSeconds(elapsedTime),
            status: isNonMinuteEvent ? EventUploadStatus.SUCCESS : EventUploadStatus.PENDING
        };

        // TODO: add undo for events which are sent to back-end
        const action = isNonMinuteEvent ? (
            <Button color='secondary' size='small' onClick={() => { handleUndo(eventInternalId); }}>
                {t('undo')}
            </Button>
        ) : undefined;

        const notificationMessage = (
            <span>
                <strong>[{eventDoer.number}] {eventDoer.nickname}</strong>
                <span> - "<strong>{translatedEventName}</strong>"</span>
            </span>
        );

        notify.showNotification(notificationMessage, 'info', action);

        dispatch({ type: GameDispatchActions.ADD_EVENT, value: eventToUpload });

        dispatch({ type: GameDispatchActions.CLOSE_INSERT_MENU });

        if (!isNonMinuteEvent) {
            saveEvent(eventToUpload);
        }

        if (eventTypeId === 5) {
            checkFouls(selectedPlayer, events);
        }

        if (!settings.isTestMode && isGameStarted && !isGameCompleted) {
            // state is not updated yet, so we will save the previous one
            saveBackup(matchId);
        }
    };

    return (
        <Grid item xs={props.cellSize}>
            <Badge
                badgeContent={props.eventCount ?? 0}
                color='info'
                component='div'
                className='player-fouls-badge'
            >
                <Button
                    variant='outlined'
                    className={buttonClassName}
                    startIcon={buttonIcon}
                    onClick={onButtonClick}
                >
                    {translatedEventName}
                </Button>
            </Badge>
        </Grid>
    );
}
