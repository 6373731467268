import { isDevEnv } from '../common/environmentHelper';

const baseApiURL = isDevEnv() ? 'https://localhost:5001/api' : 'https://livescore.amfl-bg.com/admin/matchs/api';

interface EndpointsInterface {
    matchData: string;
    startMatch: string;
    startSecondHalf: string;
    completeMatch: string;
    matchEvents: string;
    matchEvent: string;
};

const matchEventsUrl = `${baseApiURL}/matchEvent/{id}`;

export const endpoints: EndpointsInterface = {
    matchData: `${baseApiURL}/matchData/{id}`,
    startMatch: `${baseApiURL}/startMatch/{id}`,
    startSecondHalf: `${baseApiURL}/startSecondHalf/{id}`,
    completeMatch: `${baseApiURL}/completeMatch/{id}`,
    matchEvents: matchEventsUrl,
    matchEvent: `${matchEventsUrl}/{eventId}`
};
